import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './style.css'
import VueCookies from 'vue-cookies'
import { createPinia } from 'pinia'
import gAuthPlugin from 'vue3-google-oauth2'
import PrimeVue from 'primevue/config';
import DialogService from 'primevue/dialogservice';
import Button from 'primevue/button';
import DynamicDialog from 'primevue/dynamicdialog';
import Dialog from 'primevue/dialog';
import Tooltip from 'primevue/tooltip';
import Camera from "simple-vue-camera";
// translate to other languajes
import { createI18n } from 'vue-i18n'

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'vue-multiselect/dist/vue-multiselect.css';

const messages = {
  en: {
    platform: {
      gallery: "Project Gallery",
      create: "Create project using our models through our API",
      list: "Projects list:",
      create_caps: "CREATE PROJECT",
      name: "Project Name",
      desc: "Description",
      input: "Input Type",
      image: "Image",
      video: "Video",
      models: "Models",
      search: "Search Model",
      done: "Done",
      delete: "Delete",
      edit: "Edit",
      try_me: "Try me",
      create_button: "Create Project",
      try: "Try models:",
      detection: "Object Detection",
      try_model: "Try the facebook/detr-resnet-50 model.",
      create_project: "Create a project with the facebook/detr-resnet-50.",
      choose: "Choose an image from your PC.",
      press: "Press \"Try Me\" on the project to run it.",
      choose_file: "Choose File",
      display: "Display project results:",
      run_project: "Run project to see result",
      integrate: "How to integrate in your project",
      api: "Make an API call from your project using the project URL.",
      results: "Get results from the model:",
      example: "Example object detection code fragment:"
    },
    contactForm: {
      title: "SEND US A MESSAGE",
      title2: "CONTACT US",
      description: "At Mirovision we are passionate about continuously improving our products and services to provide the best possible experience to our users. We believe that the best way to achieve this is through open and honest communication with our customers. That’s why we invite you to contact us if you have any questions, suggestions or comments. Your opinion is very important to us and will help us to continue growing and improving.",
      network: "Our social networks",
      send: "Send a Message",
      form: {
        first: "First Name*",
        second: "Last Name*",
        email: "Email*",
        phone: "Phone Number",
        body: "Message*"
      },
    },
    roadmap: {
      1: {
        title: "Birth of an Idea",
        body: "With the intention of helping people through the use of computer vision models"
      },
      2: {
        title: "Planning and Start of Development",
        body: "Planning and estimating possible problems, resources needed to develop the idea"
      },
      3: {
        title: "Change of Plans",
        body: "After a meeting with the main partner, we decided to expand the target audience"
      },
      4: {
        title: "Creation",
        body: "Creation of a corporate website and a robust infrastructure"
      },
      5: {
        title: "Refinement",
        body: "Optimization of resources, incorporation of the first AI models"
      },
      6: {
        title: "Beta Launch",
        body: "Presentation of first functionalities and product scope"
      },
    },
    errors: {
      userexists: 'User already exists',
      wrongpass: 'Wrong user or password',
      inprogressT: 'Under Construction!',
      inprogressD: 'We are working hard to bring you something awesome. Check back soon!'

    },
    select: 'Select Language',
    landing: {
      title: "Give eyes to your software",
      subtitle: "Give Your Software the Power of Sight Unlock Real-World Data with Computer Vision",
      get_started: "Get started",
      about_us: "About us",
      features: "Our features",
      setup: "Effortless Integration",
      setup_text: "Get up and running in minutes. Seamlessly combine our pre-trained models via our intuitive API, eliminating the need for complex configurations.",
      simple: "Streamlined Interface",
      simple_text: "Our user-friendly dashboard makes project management and customization a breeze. Focus on what matters most – your results.",
      ready: "Ready-to-Use Models",
      ready_text: "Choose from a wide range of state-of-the-art models",
      models: "Models",
      detection: "Object Detection",
      facial: "Facial Recognition",
      image_class: "Image Classification",
      image_seg: "Image Segmentation",
      pattern: "Patern Recognition",
      try: "Try Now",
      car: "Car Detection",
      time: "Time Lapse annotation",
      opinions: "Users Opinions",
      pricing: "Our Pricing Plan",
      select: "You can get ALL access by selecting your plan!",
      read: "Read more",
      basic: "Basic",
      basic_desc: "Ideal for personal projects and experimentation",
      start: "Start Now",
      monthly: "/Month",
      payment: "Monthly payment",
      included: "What\'s included:",
      limited: "Limited model access",
      personal: "Personal model page",
      chat: "Chat support",
      optimize: "Optimized API performance",
      mobile: "Mobile app access",
      unlimited: "Unlimited models",
      pro: "Pro",
      pro_desc: "Unleash the full potential of computer vision for your business",
      yearly: "/Year",
      once: "(save over 15%!)",
      link: "Quick Link",
      home: "Home",
      who: "Who we are",
      help: "Help",
      popular: "Most Popular",
      best: "Best Products",
      new: "New Features",
      platform: "Platform",
      slogan: '"Empower Your Projects with AI-Powered Computer Vision"',
      whatis: 'What is CV?',
      definition: '"Computer vision is an interdisciplinary scientific field that deals with how computers can gain high-level understanding from digital images or videos. From the perspective of engineering, it seeks to understand and automate tasks that the human visual system can do"',
      meet: 'Meet the team',
      contact: 'Contact Us',
      request: 'Request demo',
      about: 'About us'
    },
    header: {
      about: "About Us",
      meet: "Meet the team, discover our project and prepare to be part of the future of the computer vision.",
      ourteam: "Meet our team",
      revo: "Revolutionize Your World",
      imagine: "Imagine a world where computers can truly see. Not just pixels and patterns, but the essence of what they capture. A world where machines can unlock insights hidden from human eyes, transforming industries and shaping the future.",
      building: "We're building that world.\nMirovision is creating a groundbreaking computer vision platform that empowers you to harness \nthe \npower of AI. Our platform offers a suite of pre-trained models and intuitive tools that make \nadvanced computer vision accessible to everyone, from developers to entrepreneurs and \nresearchers.",
      team: "Check our team members",
      try: 'Try model',
      cameras: 'Cameras',
      models: 'Models',
      signin: 'Sign in',
      signup: 'Sign up',
      contact: 'Contact us',
      lang: 'Select Language'
    },
    sign: {
      forgotpass: "Do you forgot your password?",
      create: 'Create your account',
      login: 'Login',
      email: 'Email',
      password: 'password',
      login2: 'Log in',
      account: 'Do not have an account?',
      registerhere: 'Register here',
      or: 'or',
      google: 'LOG IN WITH GOOGLE',
      name: 'First name',
      surname: 'Last name',
      confirm: 'confirm password',
      register: 'Register',
      loginhere: 'Log in here',
      googlereg: 'SIGN UP WITH GOOGLE',
      hasaccount: 'Already have an account?',
      modal: {
        title: "Reset Your Password",
        subtitle: "Check your email to reset your Password",
        email: "Email",
        confirmation: " Confirm Email *",
        clear: "Clear",
        discard: "Discard",
        submit: "Send Reset Email"
      },
    },
    model: {
      panel: "Admin Panel",
      projects: "you do not have any projects",
      create: "Create Project",
      gallery: "Model Gallery",
      choose: "Choose and upload AI models to use in your projects",
      admin: "Admin Upload Model",
      import: "IMPORT YOUR MODEL",
      upload: "Choose Model",
      upbtn: "UPLOAD",
      object: 'Object recognition',
      hotdog: 'Hotdog recognition',
      select: 'Select image from computer',
      try: 'TRY MODEL'
    },
    contactUs: {
      title: "Contact Us",
      form: {
        name: "Name",
        email: "Email",
        subject: "Subject",
        select: "Options",
        suggestion: "Suggestion",
        feedBack: "FeedBack",
        bug: "Bug",
        securityBreach: "Security Breach",
      },
      description: "Description",
      agree: "I agree to the",
      termsConditions: "terms and conditions",
      submit: "Submit",
    },
    benefits: {
      1: {
        title: "Maximized Productivity",
        body: "Easiest-to-use integration platform\nSeamlessly import computer vision models into your projects\nConnect multiple cameras, webcams, phones, or Raspberry Pis for input"
      },
      2: {
        title: "Versatile Input Options",
        body: "Import images, videos, streaming URLs, mobile phone feeds, screen sharing, or even camera feeds from robots\nFlexibility to choose the input source that suits your needs"
      },
      3: {
        title: "Streamlined Output Access",
        body: "Retrieve the output of your computer vision models via API\nStore the results conveniently with a free or subscription plan\nGain valuable insights or receive real-time responses for triggering actions or decision-making in robots"
      }
    },
    features: {
      1: {
        title: "Multiple Inputs",
        body: "Import and process multiple inputs simultaneously for enhanced versatility and comprehensive analysis"
      },
      2: {
        title: "Extensive Model Collection",
        body: "Choose from a diverse range of computer vision models tailored to various applications\nContinuously expanding the collection with contributions from our ML engineers, the community, and renowned sources like Hugging Face"
      },
      3: {
        title: "Ultra Simple UX/UI",
        body: "User-friendly interface designed for easy integration and intuitive operation\nSimplify and optimize your computer vision model usage without compromising on performance"
      }
    }
  },
  es: {
    platform: {
      gallery: "Galería de Proyectos",
      create: "Crear proyecto usando nuestros modelos a través de nuestra API",
      list: "Lista de proyectos:",
      create_caps: "CREAR PROYECTO",
      name: "Nombre del Proyecto",
      desc: "Descripción",
      input: "Tipo de Entrada",
      image: "Imagen",
      video: "Vídeo",
      models: "Modelos",
      search: "Buscar Modelo",
      done: "Hecho",
      delete: "Eliminar",
      edit: "Editar",
      try_me: "Pruébame",
      create_button: "Crear Proyecto",
      try: "Probar modelos:",
      detection: "Detección de Objetos",
      try_model: "Prueba el modelo facebook/detr-resnet-50.",
      create_project: "Crea un proyecto con el facebook/detr-resnet-50.",
      choose: "Elige una imagen de tu PC.",
      press: "Presiona \"Pruébame\" en el proyecto para ejecutarlo.",
      choose_file: "Elegir Archivo",
      display: "Mostrar resultados del proyecto:",
      run_project: "Ejecutar proyecto para ver el resultado",
      integrate: "Cómo integrar en tu proyecto",
      api: "Realiza una llamada API desde tu proyecto usando la URL del proyecto.",
      results: "Obtener resultados del modelo:",
      example: "Fragmento de código de ejemplo para detección de objetos:"
    },
    contactForm: {
      title: "ENVÍANOS UN MENSAJE",
      title2: "CONTÁCTANOS",
      description: "En Mirovision nos apasiona mejorar continuamente nuestros productos y servicios para brindar la mejor experiencia posible a nuestros usuarios. Creemos que la mejor manera de lograrlo es a través de una comunicación abierta y honesta con nuestros clientes. Por eso te invitamos a que te pongas en contacto con nosotros si tienes alguna pregunta, sugerencia o comentario. Tu opinión es muy importante para nosotros y nos ayudará a seguir creciendo y mejorando.",
      network: "Nuestras redes sociales",
      send: "Enviar mensaje",
      form: {
        first: "Nombre*",
        second: "Apellido*",
        email: "Correo electrónico*",
        phone: "Número de teléfono",
        body: "Mensaje*"
      },
    },    
    roadmap: {
      1: {
        title: "Nacimiento de una Idea",
        body: "Con la intención de ayudar a las personas mediante el uso de modelos de visión por computadora"
      },
      2: {
        title: "Planificación e inicio del Desarrollo",
        body: "Se planifica y estima los posible problemas, recursos necesarios para desarrollar la idea"
      },
      3: {
        title: "Cambio de Planes",
        body: "Tras una reunión con el principal socio, decidimos expandir el público objetivo"
      },
      4: {
        title: "Creación",
        body: "Creación de una página web corporativa y una robusta infrastructura"
      },
      5: {
        title: "Perfeccionamiento",
        body: "Optimización de recursos, incorporación de los primero modelos de IA"
      },
      6: {
        title: "Lanzamiento de la Beta",
        body: "Presentación de primeras funcionalidades y alcance del producto"
      },
    },
    errors: {
      userexists: "El usuario ya existe",
      wrongpass: "Usuario o contraseña incorrecta",
      inprogressT: '¡En construcción!',
      inprogressD: 'Estamos trabajando para ofrecerte algo increíble. ¡Vuelve pronto!'
    },
    select: "Seleccionar idioma",
    landing: {
      title: "Dale ojos a tu software",
      subtitle: "Dale a tu software el poder de la visión Desbloquea datos del mundo real con visión por ordenador",
      get_started: "Empieza",
      about_us: "Sobre nosotros",
      features: "Nuestras prestaciones",
      setup: "Integración sin esfuerzo",
      setup_text: "Póngase en marcha en cuestión de minutos. Combine sin problemas nuestros modelos preentrenados a través de nuestra intuitiva API, eliminando la necesidad de complejas configuraciones",
      simple: "Interfaz optimizada",
      simple_text: "Nuestro panel de control fácil de usar hace que la gestión de proyectos y la personalización sean pan comido. Céntrese en lo más importante: sus resultados.",
      ready: "Modelos listos para usar",
      ready_text: "Simplemente usa los modelos que quieras, proporcionamos una gran variedad de modelos preparados para usar.",
      basic_desc: "Ideal para proyectos personales y experimentación",
      models: "Modelos",
      detection: "Detección de Objetos",
      facial: "Reconociminto Facial",
      image_class: "Clasificación de Imágenes",
      image_seg: "Segmentación de Imágenes",
      pattern: "Reconocimiento de Patrones",
      try: "Prueba Ahora",
      car: "Detección de Coches",
      time: "Anotación de Time Lapse",
      opinions: "Opinion de los Usuarios",
      pricing: "Nuestros Precios",
      select: "Obten accesso a TODO seleccionando un plan!",
      read: "Descubrir más",
      basic: "Básico",
      start: "Empieza Ahora",
      monthly: "/Mes",
      payment: "Pago Mensual",
      included: "Incluye:",
      limited: "Acceso limitado a los modelos",
      personal: "Página personal de modelos",
      chat: "Soporte de Chat",
      optimize: "Rendimiento optimizado de la API",
      mobile: "Acceso a la aplicación móvil",
      unlimited: "Modelos Ilimitados",
      pro: "Pro",
      pro_desc: "Libere todo el potencial de la visión por ordenador para su negocio",
      yearly: "/Año",
      once: "(¡ahorra más de un 15%!)",
      link: "Links Rápidos",
      home: "Inicio",
      who: "Quiénes somos",
      help: "Ayuda",
      popular: "Más Popular",
      best: "Mejores Productos",
      new: "Nuevas Prestaciones",
      platform: "Plataforma",
      slogan: "«Potencia tus proyectos con Visión por Computadora impulsada por IA»",
      whatis: "¿Qué es CV?",
      definition: "«La visión por computadora es un campo científico interdisciplinario que se ocupa de cómo las computadoras pueden obtener una comprensión de alto nivel a partir de imágenes o videos digitales. Desde una perspectiva de ingeniería, busca comprender y automatizar tareas que el sistema visual humano puede realizar»",
      models: "Modelos de CV",
      meet: "Conoce al equipo",
      contact: "Contáctanos",
      request: "Solicitar demostración",
      about: "Sobre Nosotros"
    },
    header: {
      about: "Sobre Nosotros",
      meet: "Conoce al equipo, descubre nuestro proyecto y prepárate para formar parte del futuro de la visión por computadora.",
      ourteam: "Conoce a nuestro equipo",
      revo: "Revoluciona Tu Mundo",
      imagine: "Imagina un mundo donde las computadoras puedan ver realmente. No solo píxeles y patrones, sino la esencia de lo que capturan. Un mundo donde las máquinas puedan desbloquear conocimientos ocultos a los ojos humanos, transformando industrias y dando forma al futuro.",
      building: "Estamos construyendo ese mundo.\nMirovision está creando una plataforma revolucionaria de visión por computadora que te permite aprovechar el poder de la IA. Nuestra plataforma ofrece un conjunto de modelos preentrenados y herramientas intuitivas que hacen que la visión por computadora avanzada sea accesible para todos, desde desarrolladores hasta emprendedores e investigadores.",
      team: "Nuestro equipo",
      try: "Probar modelo",
      cameras: "Cámaras",
      models: "Modelos",
      signin: "Iniciar sesión",
      signup: "Registrarse",
      contact: "Contáctanos",
      lang: "Seleccionar idioma"
    },
    sign: {
      forgotpass: "¿Olvidaste tu contraseña?",
      create: "Crea tu cuenta",
      login: "Iniciar sesión",
      email: "Correo electrónico",
      password: "Contraseña",
      login2: "Ingresar",
      account: "¿No tienes una cuenta?",
      registerhere: "Regístrate aquí",
      or: "o",
      google: "INICIAR SESIÓN CON GOOGLE",
      name: "Nombre",
      surname: "Apellido",
      confirm: "Confirmar contraseña",
      register: "Registrarse",
      loginhere: "Inicia sesión aquí",
      googlereg: "REGISTRARSE CON GOOGLE",
      hasaccount: "¿Ya tienes una cuenta?",
      modal: {
        title: "Restablecer tu contraseña",
        subtitle: "Revisa tu correo electrónico para restablecer tu contraseña",
        email: "Correo electrónico",
        confirmation: "Confirmar correo electrónico *",
        clear: "Limpiar",
        discard: "Descartar",
        submit: "Enviar"
      } 
    },
    model: {
      panel: "Panel de Administración",
      projects: "No tienes ningún proyecto",
      create: "Crear Proyecto",
      gallery: "Galería de Modelos",
      choose: "Elija y cargue modelos de IA para usar en sus proyectos",
      admin: "Administrar Carga de Modelo",
      import: "IMPORTAR SU MODELO",
      upload: "Elegir Modelo",
      upbtn: "CARGAR",
      object: "Reconocimiento de objetos",
      hotdog: "Reconocimiento de hotdogs",
      select: "Seleccionar imagen del equipo",
      try: "PROBAR MODELO"
    },
    contactUs: {
      title: "Contáctenos",
      form: {
        name: "Nombre",
        email: "Email",
        subject: "Asunto",
        select: "Opciones",
        suggestion: "Sugerencia",
        feedBack: "Comentarios",
        bug: "Error",
        securityBreach: "Violación de seguridad",
      },
      description: "Descripción",
      agree: "Acepto los",
      termsConditions: "términos y condiciones",
      submit: "Enviar",
    },
    benefits: {
      1: {
        title: "Productividad Maximizada",
        body: "Plataforma de integración más fácil de usar\nImporta modelos de visión por computadora fácilmente en tus proyectos\nConecta múltiples cámaras, webcams, teléfonos o Raspberry Pis como entradas"
      },
      2: {
        title: "Opciones de Entrada Versátiles",
        body: "Importa imágenes, videos, URL de transmisión, feeds de teléfonos móviles, uso compartido de pantalla e incluso feeds de cámaras de robots"
      },
      3: {
        title: "Acceso Simplificado a la Salida",
        body: "Obtén los resultados de tus modelos de visión por computadora a través de una API\nAlmacena los resultados de manera conveniente con un plan gratuito o de suscripción\nObtén información valiosa o recibe respuestas en tiempo real para desencadenar acciones o tomar decisiones en robots"
      }
    },
    features: {
      1: {
        title: "Entradas Múltiples",
        body: "Importa y procesa múltiples entradas simultáneamente para una mayor versatilidad y análisis exhaustivo"
      },
      2: {
        title: "Amplia Colección de Modelos",
        body: "Elige entre una amplia gama de modelos de visión por computadora adaptados a diversas aplicaciones\nColección en constante expansión con contribuciones de nuestros ingenieros de aprendizaje automático (ML), la comunidad y fuentes reconocidas como Hugging Face"
      },
      3: {
        title: "Interfaz de Usuario Sencilla y Eficiente",
        body: "Interfaz fácil de usar diseñada para una integración sencilla y una operación intuitiva\nSimplifica y optimiza el uso de tus modelos de visión por computadora sin comprometer el rendimiento"
      }
    }

  },
  fr: {
    platform: {
      gallery: "Galerie de Projets",
      create: "Créer un projet en utilisant nos modèles via notre API",
      list: "Liste des projets :",
      create_caps: "CRÉER UN PROJET",
      name: "Nom du Projet",
      desc: "Description",
      input: "Type d'Entrée",
      image: "Image",
      video: "Vidéo",
      models: "Modèles",
      search: "Rechercher un Modèle",
      done: "Terminé",
      delete: "Supprimer",
      edit: "Modifier",
      try_me: "Essaie-moi",
      create_button: "Créer un Projet",
      try: "Essayer les modèles :",
      detection: "Détection d'Objets",
      try_model: "Essayer le modèle facebook/detr-resnet-50.",
      create_project: "Créer un projet avec le facebook/detr-resnet-50.",
      choose: "Choisissez une image de votre PC.",
      press: "Appuyez sur \"Essaie-moi\" sur le projet pour l'exécuter.",
      choose_file: "Choisir un Fichier",
      display: "Afficher les résultats du projet :",
      run_project: "Exécuter le projet pour voir le résultat",
      integrate: "Comment intégrer dans votre projet",
      api: "Faire un appel API depuis votre projet en utilisant l'URL du projet.",
      results: "Obtenir les résultats du modèle :",
      example: "Exemple de fragment de code de détection d'objets :"
    },
    contactForm: {
      title: "ENVOYEZ-NOUS UN MESSAGE",
      title2: "CONTACTEZ-NOUS",
      description: "Chez Mirovision, nous sommes passionnés par l'amélioration continue de nos produits et services afin d'offrir la meilleure expérience possible à nos utilisateurs. Nous pensons que la meilleure façon d'y parvenir est d'avoir une communication ouverte et honnête avec nos clients. C'est pourquoi nous vous invitons à nous contacter si vous avez des questions, des suggestions ou des commentaires. Votre avis est très important pour nous et nous aidera à continuer à grandir et à nous améliorer.",
      network: "Nos réseaux sociaux",
      send: "Envoyer",
      form: {
        first: "Prénom*",
        second: "Nom*",
        email: "Email*",
        phone: "Numéro de téléphone",
        body: "Message*"
      },
    },    
    roadmap: {
      1: {
        title: "Naissance d'une idée",
        body: "Avec l'intention d'aider les gens grâce à l'utilisation de modèles de vision par ordinateur"
      },
      2: {
        title: "Planification et début du développement",
        body: "Planification et estimation des problèmes potentiels, des ressources nécessaires pour développer l'idée"
      },
      3: {
        title: "Changement de plans",
        body: "Après une réunion avec le partenaire principal, nous avons décidé d'élargir le public cible"
      },
      4: {
        title: "Création",
        body: "Création d'un site web d'entreprise et d'une infrastructure robuste"
      },
      5: {
        title: "Perfectionnement",
        body: "Optimisation des ressources, intégration des premiers modèles d'IA"
      },
      6: {
        title: "Lancement de la version bêta",
        body: "Présentation des premières fonctionnalités et de la portée du produit"
      },
    },
    errors: {
      userexists: 'L\'utilisateur existe déjà',
      wrongpass: 'Utilisateur ou mot de passe incorrect',
      inprogressT: 'En construction !',
      inprogressD: 'Nous travaillons dur pour vous offrir quelque chose de génial. Revenez bientôt !'
    },
    select: 'Sélectionner la langue',
    landing: {
      about: "À Propos de Nous",
      title: "Donnez des yeux à votre logiciel",
      subtitle: "Donnez à votre logiciel le pouvoir de la vue Débloquez les données du monde réel avec la vision par ordinateur",
      get_started: "Commencer",
      about_us: "À propos de nous",
      features: "Nos fonctionnalités",
      setup: "Effortless Integration",
      setup_text: " Soyez opérationnel en quelques minutes. Combinez en toute transparence nos modèles pré-entraînés via notre API intuitive, éliminant ainsi le besoin de configurations complexes.",
      simple: "Interface simplifiée",
      simple_text: "Notre tableau de bord convivial facilite la gestion et la personnalisation des projets. Concentrez-vous sur ce qui compte le plus : vos résultats.",
      ready: "Modèles prêts à l'emploi",
      ready_text: "Choisissez parmi une large gamme de modèles de pointe.",
      models: "Modèles",
      detection: "Détection d'objets",
      facial: "Reconnaissance faciale",
      image_class: "Classification d'images",
      image_seg: "Segmentation d'images",
      pattern: "Reconnaissance de motifs",
      try: "Essayer maintenant",
      car: "Détection de voiture",
      time: "Annotation de laps de temps",
      opinions: "Opinions des utilisateurs",
      pricing: "Notre plan tarifaire",
      select: "Vous pouvez obtenir un ACCÈS TOTAL en sélectionnant votre plan !",
      read: "Lire la suite",
      basic: "Basique",
      basic_desc : "Idéal pour les projets personnels et l'expérimentation.",
      start: "Commencer maintenant",
      monthly: "/Mois",
      payment: "Paiement mensuel",
      included: "Ce qui est inclus :",
      limited: "Accès limité aux modèles",
      personal: " Page de modèle personnel",
      chat: "Support par chat",
      optimize: "Performances optimisées de l'API",
      mobile: "Accès à l'application mobile",
      unlimited: "Modèles illimités",
      pro: "Pro",
      pro_desc : "Libérez tout le potentiel de la vision par ordinateur pour votre entreprise.",
      yearly: "/An",
      once: "(économisez plus de 15 % !)",
      link: "Lien rapide",
      home: "Accueil",
      who: "Qui sommes-nous",
      help: "Aide",
      popular: "Le plus populaire",
      best: "Meilleurs produits",
      new: "Nouvelles fonctionnalités",
      platform: "Plateforme",
      slogan: '"Donnez de la puissance à vos projets grâce à la vision par ordinateur alimentée par l\'IA"',
      whatis: 'Qu\'est-ce que la CV ?',
      definition: '"La vision par ordinateur est un domaine scientifique interdisciplinaire qui traite de la manière dont les ordinateurs peuvent acquérir une compréhension de haut niveau à partir d\'images numériques ou de vidéos. Du point de vue de l\'ingénierie, elle cherche à comprendre et à automatiser les tâches que le système visuel humain peut effectuer"',
      models: 'Modèles de CV',
      meet: 'Rencontrez l\'équipe',
      contact: 'Contactez-nous',
    },
    header: {
      about: "À Propos de Nous",
      meet: "Rencontrez l'équipe, découvrez notre projet et préparez-vous à faire partie de l'avenir de la vision par ordinateur.",
      ourteam: "Rencontrez notre équipe",
      revo: "Révolutionnez Votre Monde",
      imagine: "Imaginez un monde où les ordinateurs peuvent réellement voir. Pas seulement des pixels et des motifs, mais l'essence de ce qu'ils capturent. Un monde où les machines peuvent révéler des informations cachées aux yeux humains, transformant des industries et façonnant l'avenir.",
      building: "Nous construisons ce monde.\nMirovision crée une plateforme révolutionnaire de vision par ordinateur qui vous permet d'exploiter le pouvoir de l'IA. Notre plateforme propose une suite de modèles pré-entraînés et des outils intuitifs qui rendent la vision par ordinateur avancée accessible à tous, des développeurs aux entrepreneurs et chercheurs.",
      team: "Consultez les membres de notre équipe",
      try: 'Essayer le modèle',
      cameras: 'Caméras',
      models: 'Modèles',
      signin: 'Se connecter',
      signup: 'S\'inscrire',
      contact: 'Contactez-nous',
      lang: 'Sélectionner la langue'
    },
    sign: {
      forgotpass: "Avez-vous oublié votre mot de passe?",
      create: 'Créez votre compte',
      login: 'Se connecter',
      email: 'Email',
      password: 'Mot de passe',
      login2: 'Se connecter',
      account: 'Vous n\'avez pas de compte ?',
      registerhere: 'Inscrivez-vous ici',
      or: 'ou',
      google: 'SE CONNECTER AVEC GOOGLE',
      name: 'Prenom',
      surname: 'Nom de famille',
      confirm: 'confirmer le mot de passe',
      register: 'S\'inscrire',
      loginhere: 'Connectez-vous ici',
      googlereg: 'S\'INSCRIRE AVEC GOOGLE',
      hasaccount: 'Vous avez déjà un compte ?',
      modal: {
        title: "Réinitialiser votre mot de passe",
        subtitle: "Veuillez vérifier votre courriel pour réinitialiser votre mot de passe",
        email: "Email",
        confirmation: "Confirmer l'email *",
        clear: "Effacer",
        discard: "Annuler",
        submit: "Envoyer"
      }      
    },
    model: {
      panel: "Panneau d'administration",
      projects: "Vous n'avez aucun projet",
      create: "Créer un projet",
      gallery: "Galerie de Modèles",
      choose: "Choisissez et téléchargez des modèles d'IA à utiliser dans vos projets",
      admin: "Admin Télécharger le Modèle",
      import: "IMPORTER VOTRE MODÈLE",
      upload: "Choisir le Modèle",
      upbtn: "TÉLÉCHARGER",
      object: 'Reconnaissance d\'objets',
      hotdog: 'Reconnaissance de hot-dogs',
      select: 'Sélectionner une image depuis l\'ordinateur',
      try: 'ESSAYER LE MODÈLE'
    },
    contactUs: {
      title: "Nous contacter",
      form: {
        name: "Prenom",
        email: "Email",
        subject: "Sujet",
        select: "Options",
        suggestion: "Suggestion",
        feedBack: "Retour d'information",
        bug: "Problème",
        securityBreach: "Violation de sécurité",
      },
      description: "Description",
      agree: "J'accepte les",
      termsConditions: "conditions générales",
      submit: "Envoyer",
    },
    benefits: {
      1: {
        title: "Productivité Maximale",
        body: "Plateforme d'intégration la plus simple à utiliser\nImportez facilement des modèles de vision par ordinateur dans vos projets\nConnectez plusieurs caméras, webcams, téléphones ou Raspberry Pis en tant qu'entrées"
      },
      2: {
        title: "Options d'Entrée Polyvalentes",
        body: "Importez des images, des vidéos, des URL de streaming, des flux de téléphones mobiles, le partage d'écran ou même des flux de caméras provenant de robots\nFlexibilité pour choisir la source d'entrée qui convient à vos besoins"
      },
      3: {
        title: "Accès Simplifié aux Résultats",
        body: " Récupérez les résultats de vos modèles de vision par ordinateur via une API\nStockez les résultats de manière pratique avec un plan gratuit ou d'abonnement\nObtenez des informations précieuses ou recevez des réponses en temps réel pour déclencher des actions ou prendre des décisions dans les robots"
      }
    },
    features: {
      1: {
        title: " Entrées Multiples",
        body: "Importez et traitez simultanément plusieurs entrées pour une polyvalence accrue et une analyse complète"
      },
      2: {
        title: "Vaste Collection de Modèles",
        body: "Choisissez parmi une large gamme de modèles de vision par ordinateur adaptés à diverses applications\nCollection en constante expansion avec des contributions de nos ingénieurs ML, de la communauté et de sources renommées telles que Hugging Face"
      },
      3: {
        title: "Interface Utilisateur Ultra Simple",
        body: "Interface conviviale conçue pour une intégration facile et un fonctionnement intuitif\nSimplifiez et optimisez l'utilisation de vos modèles de vision par ordinateur sans compromettre les performances"
      }
    }

  }
}

// 2. Create i18n instance with options
const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
})

const pinia = createPinia()
let gauthClientId = '1036106026976-227iii82mh3gt8nml43fblrvr3gkdarm.apps.googleusercontent.com';
const app = createApp(App).use(gAuthPlugin, {
  clientId: gauthClientId,
  scope: 'email',
  prompt: 'consent',
  plugin_name: 'Area'
}).use(router).use(VueCookies).use(pinia);
app.use(PrimeVue);
app.use(DialogService);
app.use(i18n)
app.directive('tooltip', Tooltip);
app.component('Button', Button);
app.component('DynamicDialog', DynamicDialog);
app.component('Dialog', Dialog);
app.component("camera", Camera);
app.mount('#app');
