<script lang="js">
  import { defineComponent } from 'vue';

  export default defineComponent ({
    name: 'authGoogle',
  })
</script>

<template>
    <div>Redirected from google</div>
</template>