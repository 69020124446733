<template>
    <div class="model-container">
        <div class="model-select" v-for="(item, index) in items" :key="item.id">
            <button @click="selectModel(index, item)" :class="index === selected ? 'selected' : 'no-selected'">{{ item.model }}</button>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'SelectModelTry',
    props: {
    },
    data() {
        return {
            items: [],
            selected: -1,
            model: ""
        };
    },
    methods: {
        selectModel(index, item) {
            this.$emit("selectedModel", item.id);
            this.selected = index;
            this.model = item.id;
        },
        modelChosen: function () {
            this.$emit("newModel", this.model);
        }
    },
    mounted() {
        this.items.push({ id: 1, model: this.$t('model.object') });
        this.items.push({ id: 2, model: this.$t('model.hotdog') });
    }
}
</script>

<style scoped>
.selected {
    padding: 10px 30px;
    background-color:cornflowerblue;
    border-radius: 0.2em;
    font-weight: bolder;
    color: black;
    margin: 1em 5em;
    outline: 3px solid orange;
}

.model-select {
    display: flex;
    flex-direction: column;
}

.model-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-selected {
    padding: 10px 30px;
    background-color:cornflowerblue;
    border-radius: 0.2em;
    font-weight: bolder;
    color: black;
    margin: 1em 5em;
}

.model-choose {
    padding: 10px 30px;
    background-color: orange;
    border-radius: 0.2em;
    font-weight: bolder;
    color: black;
    margin: 1em 5em;
}
</style>
  