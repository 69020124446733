<template>
    <div class="project">
        <div v-if="edit == true">
            <p class="title">{{ $t('platform.name') }}*</p>
            <input class='input' type="text" v-model="projectName">
            <p class="title">{{ $t('platform.desc') }}</p>
            <input class='input' type="text" v-model="projectDescription">
            <p class="title">{{ $t('platform.input') }}*</p>
            <div class="type">
                <button :class="image" @click="changeType">{{ $t('platform.image') }}</button>
                <button :class="video" @click="changeType">{{ $t('platform.video') }}</button>
            </div>
            <p class="title">{{ $t('platform.models') }}*</p>
            <div class="models">
                <input type="text" :placeholder="$t('platform.search')" class="input" v-model="search"
                    @input="filterModels">
                <div class="card-container">
                    <div v-for="(model, index) in selectedModels" @click="selectModel(index)"
                        :class="['model-card', model.selected ? 'selected' : '']" @mouseover="showDescription(index)"
                        @mouseout="hideDescription(index)">
                        <h3 class="model-name">{{ model.name }}</h3>
                        <p class="model-desc" v-if="hoveredIndex === index">{{ model.desc }}</p>
                    </div>
                </div>
            </div>
            <p class="title">Actions*</p>
            <multiselect v-model="action" tag-placeholder="Add this as new tag" placeholder="Search for actions to take" label="name"
                 track-by="name" :options="options" :multiple="true" :taggable="true" :close-on-select="false" open-direction="top" @tag="addTag"></multiselect>
            <button @click="createProject" class="button_1">{{ $t('platform.done') }}</button>
            <button @click="deleteProject" class="button_2">{{ $t('platform.delete') }}</button>
        </div>
        <div v-if="edit != true">
            <h1 class="name">{{ projectName }}</h1>
            <p class="description">{{ projectDescription }}</p>
            <div class="post">
                <h1>POST</h1>
                <p>api.mirovision.com/2342567765/4567876/?{{ uuid }}</p>
            </div>
            <p class="description">Actions:</p>
            <div class="chosen-actions">
                <div v-for="(item, index) in action" :key="index">
                    {{ item.name }}
                </div>
            </div>
            <button @click="editCard" class="button_1">{{ $t('platform.edit') }}</button>
            <button @click="runProject" class="button_run">Run Project</button>
        </div>
    </div>
</template>

<style scoped>
.chosen-actions {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
}
.chosen-actions > div {
    background: #0049b0;
    color: white;
    margin-left: 0;
    margin-right: 10px;
    margin-bottom: 5px;
    padding: 0 10px;
    border-radius: 0.25rem;
    width: fit-content;
}
.multiselect >>> .multiselect__option--highlight, .multiselect >>> .multiselect__tag {
    background: #1D4ED8 !important;
}
.multiselect >>> .multiselect__tag-icon::after {
    color: white;
}

.multiselect >>> .multiselect__tags {
    border: 1px solid black;
    padding: 5px;
    margin-bottom: 1em;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    background-color: #ffffff;
    font-size: 100%;
}

.multiselect >>> .multiselect__placeholder,  .multiselect >>> .multiselect__input{
    margin-bottom: 0;
}

.button_1 {
    margin-top: 1em;
    display: inline-flex;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-right: 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background-color: #1D4ED8;
}

.button_run {
    margin-top: 1em;
    display: inline-flex;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-right: 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem; /* Keep the button's rounded corners */
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: #155724; /* Text color matching the .file-selected-message */
    background-color: #d4edda; /* Background color matching the .highlighted-text */
    border: 1px solid #c3e6cb; /* Border matching the .highlighted-text */
}

.button_run:hover {
    background-color: #c3e6cb; /* Slightly darker background on hover */
    border-color: #b1dfbb; /* Slightly darker border on hover */
}

.button_2 {
    padding-top: 0.75rem;
    border-radius: 0.5rem;
    padding-bottom: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 600;
    color: #ffffff;
    background-color: #F87171;
    background-color: #EF4444;
}

.models .input {
    margin-bottom: 20px;
    /* Space between input and cards */
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    /* Space between cards */
    margin-bottom: 10px;
}

.model-card {
    border: 1px solid #ccc;
    padding: 20px;
    width: 100%;
    /* Set card width */
    border-radius: 0.5rem;
    background-color: #ffffff;
    cursor: pointer;
}

.model-card:hover,
.model-card.selected {}

.model-card.selected {
    background-color: #e9e9e9;
    /* Highlight color for selected card */
}

.model-name {
    margin-top: 0;
    font-size: 18px;
    /* Adjust font size as needed */
}

.model-desc {
    font-size: 14px;
    /* Adjust font size as needed */
    color: #666;
    margin-top: 10px;
}


.name {
    color: #393E46;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
}

.description {
    color: #393E46;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
}

.post {
    display: flex;
    background-color: #393E46;
    align-items: center;
    justify-content: space-evenly;
    height: 70px;
    margin-bottom: 10px;
}

.post>h1 {
    background-color: #89E07B;
    color: white;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 133.333% */
    letter-spacing: 3px;
    height: fit-content;
    padding: 5px 10px;
    border-radius: 0.2em;
}

.post>p {
    color: #EEE;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 70%;
}

.true {
    background-color: #0049b0;
    color: white;
}

.select-models {
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    overflow-y: scroll;
    height: 100px;
}

.models {
    display: flex;
    flex-direction: column;
}

.title {
    color: #393E46;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.input {
    border: 1px solid black;
    padding: 5px;
    margin-bottom: 1em;

    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    color: #111827;
    background-color: #ffffff;
}

.inactive {
    border-radius: 0.2em;
    background: rgba(223, 223, 223, 0.93);
    backdrop-filter: blur(2px);
    padding: 5px 20px;
    margin: 10px;
    transition: all 0.5s;
}

.active {
    border-radius: 0.2em;
    background: #0049b0;
    color: white;
    backdrop-filter: blur(2px);
    padding: 5px 20px;
    margin: 10px;
    transition: all 0.5s;
}

.project {
    width: 455px;
    height: fit-content;
    border-radius: 0.5rem;
    background: #eef7ff;
    padding: 30px;
    margin: 10px;
}

.description-box {
    position: absolute;
    top: inherit;
    left: inherit;
    margin-left: 17%;
    margin-top: -2%;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 5px;
    color: black;
}

@media (max-width: 760px) {
    .project {
        width: 90%;
        margin: auto;
    }

    .description-box {
        display: none;
    }
}
</style>
  
<script lang="js">
import { defineComponent } from 'vue';
import { createProj, deleteProj, getModels, getActions } from '../../userService.js';
import Multiselect from 'vue-multiselect'
import Swal from 'sweetalert2';
import { showToast } from '../../utils/utils.js';

export default defineComponent({
    name: 'Project',
    data() {
        return {
            cameraId: '',
            image: 'active',
            video: 'inactive',
            search: '',
            selected: '',
            selectedId: 0,
            projectName: '',
            projectDescription: '',
            models: [
            ],
            selectedModels: [
            ],
            hoveredIndex: null,
            action: [],
            options: []
        };
    },
    props: {
        name: String,
        desc: String,
        type: Number,
        model: String,
        edit: Boolean,
        uuid: String,
        actions: Array,
    },
    mounted() {
        this.action = this.action.concat(this.actions);
        this.projectName = this.name;
        this.projectDescription = this.desc;
        this.fetchModels()
        getActions().then((res) => {
            for (const action of res.data) {
                this.options.push({ name: action.name, actions_uuid: action.uuid, edit: false})
            }
        })
        .catch((error) => {
            this.incorrect = true;
            this.alert = error;
        });
    },
    components: {
        Multiselect
    },
    methods: {
        addTag(newTag) {
            const tag = {
                name: newTag,
            }
            this.options.push(tag)
            this.action.push(tag)
        },
        showDescription(index) {
            this.hoveredIndex = index;
        },
        hideDescription(index) {
            this.hoveredIndex = null;
        },
        runProject() {
            this.$emit('run', this.uuid);
        },
        async fetchModels() {
            try {
                const res = await getModels();
                for (const model of res.data) {
                    this.models.push({
                        name: model.name,
                        desc: model.description,
                        selected: false,
                        id: model.uuid,
                    });
                    this.selectedModels.push({
                        name: model.name,
                        desc: model.description,
                        selected: false,
                        id: model.uuid,
                    });
                }
                for (var i = 0; i < this.selectedModels.length; i++) {
                    if (this.selectedModels[i].id == this.model) {
                        this.selectedModels[i].selected = 'true'
                        this.selected = this.selectedModels[i].name;
                        this.selectedId = this.selectedModels[i].id;
                    }
                }
            } catch (error) {
                this.incorrect = true;
                this.alert = error;
            }
        },
        createProject: function () {
            createProj(this.projectName, this.projectDescription, this.selectedId, Array.from(this.action.map(action => action.actions_uuid))).then((res) => {
                showToast("success", "Project successfully uploaded");
            })
            .catch((error) => {
                showToast("error", "Error uploading project");
            });
            this.$emit('edit', false);
        },
        deleteProject: function () {
            showToast("success", "Project successfully deleted");
            this.$emit('success', this.uuid);
        },
        showToast(icon, title) {
            const Toast = Swal.mixin({
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon: icon,
                title: title
            });
        },
        changeType: function () {
            if (this.image == 'active') {
                this.image = 'inactive';
                this.video = 'active';
            } else {
                this.image = 'active';
                this.video = 'inactive';
            }
        },
        selectModel: function (index) {
            for (var i = 0; i < this.selectedModels.length; i++) {
                this.selectedModels[i].selected = false;
            }
            this.selectedModels[index].selected = true;
            this.selected = this.selectedModels[index].name;
            this.selectedId = this.selectedModels[index].id;
            this.search = this.selected;
        },
        filterModels: function () {
            this.selectedModels = [];
            for (var i = 0; i < this.models.length; i++) {
                if (this.models[i].name.toLowerCase().includes(this.search.toLowerCase())) {
                    this.selectedModels.push(this.models[i]);
                }
            }
        },
        done: function () {
            this.$emit('edit', false);
            this.$emit('name', this.projectName);
            this.$emit('desc', this.projectDescription);
            this.$emit('type', this.image == 'active' ? 0 : 1);
            this.$emit('model', this.selected);
        },
        editCard: function () {
            this.$emit('edit', true);
        }
    }
})
</script>