<template>
  <div class="test-container">
    <!-- Cameras table container -->
    <div class="cameras-container">
      <div class="cameras-table">
        <!-- Cameras table title -->
        <div class="table-title">CAMERAS LIST</div>
        <!-- Cameras table headers -->
        <div class="table-headers">
          <div></div>
          <div>CAMERA</div>
          <div>MODEL</div>
          <div></div>
        </div>
        <!-- Add / delete animation container -->
        <transition-group name="fade" mode="out-in">
          <!-- For loop for every single camera -->
          <div v-for="(item, index) in items" :key="item.id">
            <!-- Camera item container -->
            <div :style="[(selectedId == item.id && selectedItem) ? selectedItemStyle : null ]" class="table-item">
              <!-- Delete icon -->
              <div @click="deleteItem(index, item)" v-if="(editing == true && editItemId == item.id)"><img src="../assets/svg/Delete.svg"/></div>
              <!-- Stopped camera container -->
              <div @click="toggleRun(index, item)" v-else-if="!item.running"><img src="../assets/svg/Play.svg"/></div>
              <!-- Running camera container -->
              <div @click="toggleRun(index)" v-else><img src="../assets/svg/Load.svg"/></div>
              <Dialog header="Live Feed" v-model:visible="showLiveFeed">
                <img class="live-feed" :src="displayImg" alt="Live feed" />
              </Dialog>
              <!-- Button to edit camera on edit mode -->
              <div v-if="(editing == true && editItemId == item.id)">
                <button class="edit-btn" role="button" @click="showEditCamera(index)">{{item.camera}}</button>
                <!-- Dialog with camera edit options -->
                <Dialog header="Connect Camera" :modal="true" v-model:visible="displayCameraEdit" >
                  <ConnectCamera v-on:newCameraId="setNewCamera"></ConnectCamera>
                </Dialog>
              </div>
              <!-- Camera name -->
              <div v-else v-on:click="selectItem(item)">{{ item.camera }}</div>
              <!-- Button to edit model on edit mode -->
              <div v-if="(editing == true && editItemId == item.id)">
                <button class="edit-btn" role="button" @click="showEditModel(index)">{{item.model}}</button>
                <Dialog header="Select Model" :modal="true" v-model:visible="displayModelEdit" >
                  <ModelSelect v-on:newModel="setNewModel"></ModelSelect>
                </Dialog>
              </div>
              <!-- Camera model -->
              <div v-else v-on:click="selectItem(item)">{{ item.model }}</div>
              <!-- Button to apply changes -->
              <div v-if="(editing == true && editItemId == item.id)" v-on:click="editApply(item, index)"><img src="../assets/svg/Ok.svg"/></div>
              <!-- Button to edit the item -->
              <div v-else v-on:click="editItem(item.id, item.camera, item.model)"><img src="../assets/svg/Edit.svg"/></div>
            </div>
          </div>
        </transition-group>
        <!-- Container to add new camera -->
        <div class="table-add">
          <div></div>
          <!-- New camera name -->
          <div class="add-input"><input ref="newCamera" v-model="newCamera" placeholder="Name" required/></div>
          <!-- New camera model -->
          <div class="add-input"><input v-model="newModel" placeholder="Model" required/></div>
          <!-- Button to add new camera -->
          <div @click="addItem"><img src="../assets/svg/Add.svg"/></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.test-container {
  display: flex;
  height: 100vh;
}
.cameras-container {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 75px;
  flex: 1;
}

.cameras-table {
  margin-top: 80px;
  width: 80%;
  user-select: none;
}

.table-title {
  display: flex;
  margin: auto;
  align-items:center;
  justify-content:center;
  height: 75px;
  text-align: center;
  vertical-align: middle;
  background: linear-gradient(90deg, rgba(0,232,148,0.4) 0%, rgba(0,225,198,1) 100%);
  color: white;
  font-weight: 600;
  font-size: 200%;
}

.table-headers, .table-item, .table-add {
  display: flex;
  margin: auto;
  align-items:center;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  background: #8EEECB;
  color: white;
  font-weight: 600;
  font-size: 150%;
}

.table-item {
  background: linear-gradient(90deg, rgba(0,232,148,0.4) 0%, rgba(0,225,198,1) 100%);
  font-size: 125%;
}

.table-add {
  opacity:80%;
  font-size: 125%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23009583FF' stroke-width='3' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.table-headers > *, .table-item > *, .table-add > * {
  flex: 40;
}

.table-headers > :first-of-type, .table-item > :first-of-type, .table-add > :first-of-type {
  flex: 10;
  display: flex;
  justify-content: center;
}

.table-headers > :last-of-type, .table-item > :last-of-type, .table-add > :last-of-type {
  flex: 10;
  display: flex;
  justify-content: center;
}

.cameras-container img {
  max-height: 30px;
  filter: invert(1);
  cursor: pointer;
}

.fade-enter {
  opacity:0;
}

.fade-enter-active{
  animation: fadein .5s;
}

.fade-leave {
  opacity:1;
}

.fade-leave-active {
  animation: fadein .5s reverse;
}

@keyframes fadein {
  from {opacity: 0;}
  to   {opacity: 1;}
}


.add-input {
  display: flex;
  justify-content: center;
}

input {
  padding-left: 10px !important;
  color: black !important;
  border-radius: 5px;
  width: 90%;
  display: block;
}

.edit-btn {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 35px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.edit-btn:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.edit-btn:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.edit-btn:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.edit-btn:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.edit-btn:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.edit-btn:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.edit-btn:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.edit-btn:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.live-feed {
  width: 500px;
  height: 500px;
}

</style>

<script lang="js">
import { defineComponent } from 'vue';
import Dialog from 'primevue/dialog/sfc';
import ConnectCamera from '../components/ConnectCamera.vue';
import ModelSelect from '../components/ModelSelect.vue';
import { useDialog } from 'primevue/usedialog';
import { createCamera, fetchCameras, deleteCamera, updateCamera, getLiveFeed } from '../cameraServices.js';

export default defineComponent({
  name: 'Cameras',
  data() {
    return {
      items: [],
      id: 0,
      userId: 0,
      selectedItem: false,
      selectedItemStyle: {
        border: "3px solid white",
        background: "linear-gradient(90deg, rgba(0,232,148,1) 0%, rgba(0,212,225,1) 100%)",
        opacity: "1 !important"
      },
      selectedId: -1,
      editItemId: -1,
      editing: false,
      newCamera: '',
      newModel: '',
      selectedData: {id: 0, camera: '', model: '', running: false, img: '', description: '', data: {}},
      displayCameraEdit: false,
      displayModelEdit: false,
      cameraId: '',
      selectedCameraEdit: -1,
      showLiveFeed: false,
      displayImg: ""
    };
  },
  created() {
    this.updateCamerasList();
  },
  components: {
    ConnectCamera,
    ModelSelect
  },
  methods: {
    updateCamerasList() {
      fetchCameras().then((res) => {
      let i = 0;
      for (const cam in res.data) {
        this.items.push({
          id: i,
          camera: res.data[cam].Camera_IP,
          model: res.data[cam].Model_ID,
          cam_id: res.data[cam].ID,
          running: false
        });
        i++;
      }
    });
    },
    // Selects the clicked camera
    selectItem(item) {
      if (this.editing)
        return;
      this.selectedItem = !this.selectedItem;
      this.selectedId = item.id;
      if (this.selectedItem)
        this.selectedData = item;
    },
    // Sets the clicked camera to edit mode
    editItem(itemId, itemCamera, itemModel) {
      this.editing = true;
      this.editItemId = itemId;
      this.editCamera = itemCamera;
      this.editModel = itemModel;
    },
    // Exits edit mode
    editApply(item, index) {
      //console.log(item);
      updateCamera(item.camera, item.model, item.cam_id).then(() => {
        this.editing = false;
        this.editItemId = -1;
      });
    },
    // Runs / stops camera
    toggleRun(index, item) {
      this.items[index].running = !this.items[index].running;
      this.showLiveFeed = true;
      getLiveFeed(item.camera, item.model).then((res) => {
        this.displayImg = "data:image/jpeg;base64," + res.data
        //console.log(this.displayImg)
      });
    },
    // Create new camera
    addItem() {
      if (this.newCamera.length < 1 || this.newModel.length < 1)
        return;
      createCamera(this.newCamera, this.newModel).then(() => {  
        let size = 0
        try {
          size = this.items[this.items.length - 1].id + 1;
        } catch (error) {
          size = 1;
        }
        this.newCamera = '';
        this.newModel = '';
        this.editing = false;
        this.editItemId = -1;
        this.editCamera = '';
        this.editModel = '';
        this.items = [];
        this.updateCameraList();
        this.$refs.newCamera.scrollIntoView({ behavior: "smooth" });
        this.items = [];
        this.updateCamerasList();
      });
    },
    // Deletes the clicked camera
    deleteItem(index, item) {
      deleteCamera(item.cam_id).then(() => {
        this.items.splice(index, 1);
      });
    },
    // Shows edit camera display
    showEditCamera(index) {
        this.displayCameraEdit = !this.displayCameraEdit;
        this.selectedCameraEdit = index;
    },
    // Shows edit model display
    showEditModel(index) {
        this.displayModelEdit = !this.displayModelEdit;
        this.selectedCameraEdit = index;
    },
    // Sets the new data for the camera
    setNewCamera(ev) {
      if (ev)
        this.items[this.selectedCameraEdit].camera = ev;
      this.displayCameraEdit = false;
      this.selectedCameraEdit = -1;
    },
    setNewModel(ev) {
      if (ev)
        this.items[this.selectedCameraEdit].model = ev;
      this.displayModelEdit = false;
      this.selectedCameraEdit = -1;
    },
    updateLive(item) {
      getLiveFeed(item.camera, item.model).then((res) => {
        //console.log(res)
        return ("data:image/jpeg;base64," + res.data);
      });
    }
  },
})
</script>