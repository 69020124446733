<!-- Register Screen 2024 -->

<template>
    <div class="register">
        <div class="register-background">
            <div class="register-card">
                <h1 class="register-title">{{ $t('sign.create') }}</h1>

                <input class="input" placeholder="Username" type="text" name="username" id="username"
                    v-model="username">
                <input class="input" :placeholder="$t('sign.email')" type="text" name="email" id="email"
                    v-model="email">
                <input class="input" :placeholder="$t('sign.password')" type="password" name="password" id="password"
                    v-model="password">
                <input class="input" :placeholder="$t('sign.confirm')" type="password" name="password-confirm"
                    id="password-confirm" v-model="confirmPassword">

                <!-- Error general para todo (hay que cambiarlo) -->
                <p v-if="errorMessage" class="wrong">{{ (errorMessage) }}</p>

                <!-- <button class="register-button" @click="registerUser">Do you forgot</button> -->
                <button class="register-button" @click="registerUser">{{ $t('sign.register') }}</button>
                <p class="noaccount">{{ $t('sign.hasaccount') }}</p>
                <router-link class="register-here" to="/login">{{ $t('sign.loginhere') }}</router-link>
                <p class="noaccount">{{ $t('sign.or') }}</p>
                <button @click="handleSignIn" class="google-register">{{ $t('sign.googlereg') }}</button>
            </div>
        </div>
    </div>
</template>


<script lang="js">
import { defineComponent } from 'vue';
import { register } from '../../services/auth-service.js';
import { useAuthStore } from "../../store/auth-store";
import { loginGoogle } from '../../services/auth-service.js';
import { inject } from "vue";

export default defineComponent({
    name: 'Register',
    data() {
        return {
            email: '',
            password: '',
            confirmPassword: '',
            alert: "",
            username: '',
            incorrect: false,
            errorMessage: ''
        };
    },
    methods: {
        registerUser() {
            this.errorMessage = "";
            if (this.username && this.email) {
                if (this.password === this.confirmPassword) {
                    register(this.username, this.email, this.password).then((res) => {
                        localStorage.setItem("login_status", "register");
                        window.location.href = "/"
                    }).catch((error) => {
                        this.incorrect = true;
                    });
                } else {
                    this.errorMessage = "The passwords must match";
                }
            } else {
                this.errorMessage = "Don't forget to fill all the fields";
            }
        },
        async handleSignIn() {
            try {
                const googleUser = await this.$gAuth.signIn();
                if (!googleUser) {
                    return null;
                }
                this.username = googleUser.getBasicProfile().getEmail();
                loginGoogle(this.username).then((res) => {
                    localStorage.setItem("userId", res.data.id);
                    this.authStore.update(true);
                    window.location.href = "/reactivity";
                })
                    .catch((error) => {
                        this.alert = error.response.data.message;
                    });;
            } catch (error) {
                return null;
            }

        },
    },
    setup() {
        const Vue3GoogleOauth = inject("Vue3GoogleOauth");
        const authStore = useAuthStore();
        return {
            authStore,
            Vue3GoogleOauth,
        };
    },
    created() {
    }
})
</script>



<style>
.test {
    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    color: #111827;
    background-color: #ffffff;
}

.register-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.register {
    margin-top: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.register-here {
    color: #0049b0;
    font-weight: bolder;
    margin-bottom: -1em;
}

.register-card {
    background-color: white;
    border-radius: 2em;
    width: 30em;
    height: 40em;
    -webkit-box-shadow: 3px 5px 22px 3px rgba(0, 0, 0, 0.46);
    box-shadow: 3px 5px 22px 3px rgba(0, 0, 0, 0.46);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    rotate: 10deg;
    transition: all 1s;
    z-index: 10;
}

.input {
    border: 1px solid black;
    padding: 5px;
    margin-bottom: 1em;

    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    color: #111827;
    background-color: #ffffff;
}

.register-button {
    background: #0049b0;
    padding: 10px 40px;
    border-radius: 0.5em;
    color: white;
    font-weight: bold;
    transition: all 0.5s;
}

.register-button:hover {
    padding: 10px 50px;
}

.google-register {
    padding: 10px 30px;
    margin-right: 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    border-width: 2px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    background-color: #ffffff;
    color: #0049b0;
}

.google-register:hover {
    background-color: #0049b0;
    color: white;
}

.register-background {
    width: 30em;
    height: 40em;
    background: #0049b0;
    border-radius: 2em;
    rotate: -10deg;
    transition: all 1s;
}

.noaccount {
    margin-top: 1em;
}

.register-input {
    display: flex;
    flex-direction: column;
}

.register-background:hover {
    rotate: -15deg;
}

.register-background:hover>.register-card {
    rotate: 15deg;
}

.wrong {
    color: red;
    padding-bottom: 15px;
}

@media (max-width: 600px) {
    .register-background {
        rotate: 0deg;
        width: max-content;
        height: max-content;
    }

    .register-card {
        rotate: 0deg;
        width: max-content;
        height: max-content;
        padding: 40px 40px;
    }
}
</style>