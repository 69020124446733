<template>
    <div class="max-w-lg mx-auto my-10 bg-white rounded-lg shadow-md p-5">
        <img class="w-32 h-32 rounded-full mx-auto" src="https://picsum.photos/200" alt="Profile picture">

        <h2 class="text-center text-2xl font-semibold mt-3" v-if="name">
            Hola {{ name }}
            <i class="pi pi-verified" style="font-size: 1rem"></i>
        </h2>
        
        <p class="text-center text-gray-600 mt-1">MiroVision Admin</p>

        <div class="flex justify-center mt-5">
            <a href="#" class="text-blue-500 hover:text-blue-700 mx-3">Twitter</a>
            <a href="#" class="text-blue-500 hover:text-blue-700 mx-3">LinkedIn</a>
            <a href="#" class="text-blue-500 hover:text-blue-700 mx-3">GitHub</a>
        </div>
        <div class="mt-5">
            <h3 class="text-xl font-semibold">About Me</h3>
            <p class="text-gray-600 mt-2">Driven by the potential of computers to 'see' the world, I am passionate about the
                field of computer vision.

                I am fascinated by the ability to extract meaningful information from images and videos, allowing machines
                to understand and interact with the physical world. </p>
        </div>

        <br>

        <button type="button"
            class="flex items-center justify-center px-4 font-medium bg-white text-black h-9 rounded-md mx-auto py-3">
            Change Description
        </button>

        <br>

        <button data-toggle="modal" type="button"
            class="flex items-center justify-center px-4 font-medium bg-blue-700 text-white h-9 rounded-md mx-auto py-3">
            Change Password
        </button>



        <!-- MODAL CODE  -->
        <div role="dialog" id="modal-example" aria-hidden="false" style="display: flex;"
            class="modal fixed top-0 left-0 z-50 w-screen h-screen bg-black/30 flex items-center flex-col justify-center p-6 fade"
            tabindex="-1">

            <!-- <div class="absolute top-0 left-0 z-[0] w-full h-full" tabindex="-1"></div> -->

            <article class="modal-content flex flex-col relative m-0 rounded-md bg-white sm:my-16"
                aria-labelledby="modal-title" aria-describedby="modal-body">

                <div class="bg-gray-100 flex items-center justify-center">
                    <div class="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full">
                        <div class="flex items-center space-x-2 mb-6">
                            <!-- <img src="https://picsum.photos/200" alt="Lock Icon" class="rounded-full"> -->
                            <h1 class="text-xl font-semibold">Change Password</h1>
                        </div>
                        <p class="text-sm text-gray-600 mb-6">Update password for enhanced account security.</p>
                        <form id="changePasswordForm" class="space-y-6">
                            <div>
                                <label for="currentPassword" class="text-sm font-medium text-gray-700 block mb-2">Current
                                    Password *</label>
                                <input type="password" id="currentPassword"
                                    class="password-input form-input block w-full border border-gray-300 rounded-md shadow-sm"
                                    required>
                            </div>
                            <div>
                                <label for="newPassword" class="text-sm font-medium text-gray-700 block mb-2">New Password
                                    *</label>
                                <input type="password" id="newPassword"
                                    class="password-input form-input block w-full border border-gray-300 rounded-md shadow-sm"
                                    required>
                            </div>
                            <div>
                                <label for="confirmPassword" class="text-sm font-medium text-gray-700 block mb-2">Confirm
                                    New Password *</label>
                                <input type="password" id="confirmPassword"
                                    class="password-input form-input block border w-full border-gray-300 rounded-md shadow-sm"
                                    required>
                                <button type="button" onclick="clearConfirmPassword()"
                                    class="text-xs text-blue-600 hover:underline mt-1">Clear</button>
                            </div>
                            <div id="passwordCriteria" class="text-sm space-y-2">
                                <p class="text-red-500">Weak password. Must contain:</p>
                                <ul class="list-disc pl-5 space-y-1">
                                    <li>At least 1 uppercase</li>
                                    <li>At least 1 number</li>
                                    <li>At least 8 characters</li>
                                </ul>
                            </div>
                            <div class="flex justify-between">
                                <button data-dismiss="modal" type="button" onclick="discardChanges()"
                                    class="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:border-blue-300">Discard</button>
                                <button type="submit"
                                    class="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300">Apply
                                    Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </article>
        </div>

    </div>
</template>
  
<script>
export default {
    name: "MyProfile",
    mounted() {

        this.name = localStorage.getItem("user_gmail");
        //console.log("User Name =", this.name);

        const buttonClose = document.querySelectorAll('[data-dismiss="modal"]');
        const modal = document.querySelector('.modal');
        const trigger = document.querySelector('[data-toggle="modal"]');

        function showModal(modal) {
            modal.style.display = 'flex';
            setTimeout(() => {
                modal.classList.add('show');
            }, 100);
            modal.setAttribute('aria-hidden', 'false');
            document.body.style.overflow = 'hidden';
            document.body.classList.add('astroui-modal-open');
        }

        function dismissModal(modal) {
            modal.classList.remove('show');
            setTimeout(() => {
                modal.style.display = 'none';
            }, 200);
            modal.setAttribute('aria-hidden', 'true');
            document.body.style.overflow = '';
            document.body.classList.remove('astroui-modal-open');
        }

        dismissModal(modal);

        const getDismiss = (buttonClose, modal) => {
            buttonClose.addEventListener('click', () => {
                dismissModal(modal);
            });
        };

        buttonClose.forEach((buttonClose) => {
            getDismiss(buttonClose, modal);
        });

        trigger.addEventListener('click', () => {
            showModal(modal);
        });

        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape' && modal.classList.contains('show')) {
                dismissModal(modal);
            }
        });

    },
    data() {
        return {
            name: null,
        };
    },

};
</script>
  

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

:root {
    --font-family: 'Inter', sans-serif;
    --font-size: 1rem;
    --bg: white;
}

html,
body {
    font-family: var(--font-family);
}

body {
    background-color: var(--bg);
}

.modal.fade .modal-content {
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
}

.modal.show .modal-content {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.modal-content {
    transform: scale(1.1);
    transition: visibility 0.25s ease-out, opacity 0.25s ease-out, transform 0.25s ease-out;
}

/** Modal static */

.modal.modal-static .modal-content {
    transform: scale(1.02);
}
</style>