<template>
    <Carousel id="activeClasses" :itemsToShow="3.95" :wrapAround="true" :transition="500" class="activeClasses">
        <Slide v-for="profile in profiles" :key="profile.name">
            <div class="carousel__item">
                <div class="card">
                    <div class="card-image">
                        <img :src="profile.image" alt="Imagen del modelo" />
                    </div>
                    <h3 class="card-title">{{ profile.name }}</h3>
                    <p class="card-description">{{ profile.description }}</p>
                </div>
                <!-- Número -->
                <!-- <div class="number">{{ slide }}</div> -->
            </div>
        </Slide>

        <template #addons>
            <Navigation />
            <Pagination />
        </template>
    </Carousel>
</template>




<script>
import { defineComponent } from 'vue'
import { Carousel, Pagination, Slide, Navigation } from 'vue3-carousel' // Importa Navigation también
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
    name: 'ModelCarrusel',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            profiles: [
                { name: 'Kwai-Kolors/Kolors', description: 'Effective Training of Diffusion Model for Photorealistic Text-to-Image Synthesis', image: 'https://cdn-lfs-us-1.huggingface.co/repos/a8/d4/a8d4b43313dfe4eafc8935f34ec3b415319c72e89b4fc30b55eb90a15e3eb5b4/e170c5133e89f08bd4709233df59f85e06f18194b41487346841a2df1cff98b5?response-content-disposition=inline%3B+filename*%3DUTF-8%27%27head_final3.png%3B+filename%3D%22head_final3.png%22%3B&response-content-type=image%2Fpng&Expires=1721753715&Policy=eyJTdGF0ZW1lbnQiOlt7IkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTcyMTc1MzcxNX19LCJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLWxmcy11cy0xLmh1Z2dpbmdmYWNlLmNvL3JlcG9zL2E4L2Q0L2E4ZDRiNDMzMTNkZmU0ZWFmYzg5MzVmMzRlYzNiNDE1MzE5YzcyZTg5YjRmYzMwYjU1ZWI5MGExNWUzZWI1YjQvZTE3MGM1MTMzZTg5ZjA4YmQ0NzA5MjMzZGY1OWY4NWUwNmYxODE5NGI0MTQ4NzM0Njg0MWEyZGYxY2ZmOThiNT9yZXNwb25zZS1jb250ZW50LWRpc3Bvc2l0aW9uPSomcmVzcG9uc2UtY29udGVudC10eXBlPSoifV19&Signature=N07EJJVEiKlGift5wC54WNLAdgAPpiRkEHxBGJG3p2ym0MJgANepYz3ILfmGm8qQu874eEpXv%7EHmqV9bv4WYu7jZ1upsVRccoDcMsgOI8D91%7El%7E8UWjog1XJ4IXKFRvxwwVTHJ1GpZwfkS9MQ%7EiaJbKeJWYuhIqX6QQzo16QiuM7wR7uDox%7E-arGif1sVcPZmbQAq5FpT2VpnrqISZS95djaQH4JY3qSFhRa3R7iEvAuj-OrjHdfogpXII81QBiPb8EiA6h-sxDAWzvWTAiB21xvYCAvO7oLLT-0wcyLjPpqlN-ZZX0-BFFHlUlgLXAL7mOjbQaE0Sn0b4lg1ZKIiA__&Key-Pair-Id=K24J24Z295AEI9' },
                { name: 'Stable Diffusion 3', description: 'Improved performance in image quality, typography, complex prompt understanding.', image: 'https://huggingface.co/stabilityai/stable-diffusion-3-medium/media/main/sd3demo.jpg' },
                { name: 'Jameslahm/yolov10x', description: 'YOLOv10: Real-Time End-to-End Object Detection', image: 'https://cdn-uploads.huggingface.co/production/uploads/628ece6054698ce61d1e7be3/nQ4mvkxf-rj-ryrs-vOF_.png' },
                { name: 'Yolov5n-license-plate', description: 'Ideal for applications requiring rapid identification and processing of vehicle plates', image: 'https://huggingface.co/keremberke/yolov5n-license-plate/resolve/main/sample_visuals.jpg' },
                { name: 'Yolos-fashionpedia', description: 'This is a fine-tunned object detection model for fashion.', image: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*q8TTgxX_gf6vRe5AJN2r4g.png' },
                { name: 'Yolov8m-hard-hat-detection', description: 'Designed for real-time safety monitoring by identifying hard hats in various environments', image: 'https://huggingface.co/keremberke/yolov8m-hard-hat-detection/resolve/main/thumbnail.jpg' },
            ],
        }
    },
})
</script>


<style scoped>
.activeClasses {
    .carousel__slide {
        position: relative;
    }

    .carousel__viewport {
        perspective: 100px;
    }

    .carousel__track {
        transform-style: preserve-3d;
    }

    .carousel__slide--sliding {
        transition: 0.5s;
    }

    .carousel__slide {
        opacity: 0.9;
        transform: rotateY(-20deg) scale(0.9);
    }

    .carousel__slide--active~.carousel__slide {
        transform: rotateY(20deg) scale(0.9);
    }

    .carousel__slide--prev {
        opacity: 1;
        transform: rotateY(-10deg) scale(0.95);
    }

    .carousel__slide--next {
        opacity: 1;
        transform: rotateY(10deg) scale(0.95);
    }

    .carousel__slide--active {
        opacity: 1;
        transform: rotateY(0) scale(1);
    }

    .card {
        position: relative;
        background-color: #28a745;
        color: #fff;
        padding: 20px;
        border-radius: 10px;
        text-align: center;
        width: 400px;
        height: 350px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        z-index: 1;
        overflow: hidden;
    }

    .card-image {
        width: 100%;
        height: 200px;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
        margin-bottom: 15px;
    }

    .card-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .card-title {
        font-size: 22px;
        font-weight: bold;
        margin: 10px 0;
    }

    .card-description {
        font-size: 16px;
    }

    .number {
        font-size: 30px;
        font-weight: bold;
        color: #333;
        text-align: center;
        margin-top: 30px;
    }
}
</style>