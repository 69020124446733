<template>
    <div class="flex justify-center items-center w-screen h-screen bg-white">
        <div class="container mx-auto my-4 px-4 lg:px-20">

            <div class="w-full p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                <div class="flex">
                    <h1 class="font-bold uppercase text-4xl">{{ $t('contactForm.title') }}</h1>
                </div>

                <form @submit.prevent="sendEmail">

                    <div class="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        <input
                            class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                            type="text" v-model="name" name="name" placeholder="First Name*" required />

                        <input
                            class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                            type="lastname" v-model="lastname" name="lastname" placeholder="Last Name*" required />

                        <input
                            class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                            type="email" v-model="email" name="email" placeholder="Email*" required />

                        <input
                            class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                            type="number" v-model="number" name="number" placeholder="Phone" />
                    </div>
                    <div class="my-4">
                        <textarea placeholder="Message*"
                            class="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                            name="message" v-model="message" required></textarea>
                    </div>


                    <div class="my-2 w-1/2 lg:w-1/4">
                        <button class="uppercase text-sm font-bold tracking-wide bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                        focus:outline-none focus:shadow-outline">
                        {{ $t('contactForm.send') }}
                            <i class="pi pi-send pl-2 " style="font-size: 1.4rem"></i>

                        </button>
                    </div>
                </form>
            </div>

            <div class="w-full lg:-mt-96 lg:w-2/6 px-8 py-12 ml-auto bg-blue-900 rounded-2xl">
                <div class="flex flex-col text-white">
                    <h1 class="font-bold uppercase text-4xl my-4">{{ $t('contactForm.title2') }}</h1>
                    <p class="text-gray-400">
                        {{ $t('contactForm.description') }}
                    </p>

                    <!-- <div class="flex my-4 w-2/3 lg:w-1/2">
                        <div class="flex flex-col">
                            <i class="fas fa-map-marker-alt pt-2 pr-2" />
                        </div>
                        <div class="flex flex-col">
                            <h2 class="text-2xl">Main Office</h2>
                            <p class="text-gray-400">5555 Tailwind RD, Pleasant Grove, UT 73533</p>
                        </div>
                    </div>

                    <div class="flex my-4 w-2/3 lg:w-1/2">
                        <div class="flex flex-col">
                            <i class="fas fa-phone-alt pt-2 pr-2" />
                        </div>
                        <div class="flex flex-col">
                            <h2 class="text-2xl">Call Us</h2>
                            <p class="text-gray-400">Tel: xxx-xxx-xxx</p>
                            <p class="text-gray-400">Fax: xxx-xxx-xxx</p>
                        </div>
                    </div> -->

                    <div class="flex flex-col">
                        <h2 class="text-2xl">
                            {{ $t('contactForm.network') }}
                        </h2>
                    </div>
                    <div class="flex my-4 w-2/3 lg:w-1/2">

                        <a href="https://www.linkedin.com/in/mirovision-company-000435261/"
                            class="mx-2 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                            aria-label="LinkedIn">
                            <i class="pi pi-linkedin h-6 w-6 fill-current" style="font-size: 1.4rem"></i>

                        </a>

                        <a href="https://www.instagram.com/mirovision.official/"
                            class="mx-2 transition-colors duration-300 hover:text-fuchsia-500	 dark:text-gray-300 dark:hover:fuchsia-500"
                            aria-label="LinkedIn">
                            <i class="pi pi-instagram h-6 w-6 fill-current" style="font-size: 1.4rem"></i>

                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- COMPONENT CODE -->
    </div>
</template>

<style scoped>
* {
    box-sizing: border-box;
}
</style>

<script>
import emailjs from 'emailjs-com';

export default {
    name: 'ContactUs',
    data() {
        return {
            name: '',
            lastname: '',
            email: '',
            message: '',
            number: ''
        }
    },
    methods: {
        sendEmail(e) {
            try {
                emailjs.sendForm('service_s4art7v', 'template_0tclcdl', e.target, 'eN-nAOPf82C0l4DrJ', {
                    name: this.name,
                    lastname: this.lastname,
                    email: this.email,
                    message: this.message,
                    number: this.number,
                })
            } catch (err) {
                if (err instanceof ReferenceError) {
                    alert("JSON Error: " + err.message);
                } else {
                    throw err;
                }
            }
            this.name = ''
            this.email = ''
            this.message = ''
            this.lastname = ''
            this.number = ''
        },
    }
}
</script>
