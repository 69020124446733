<template>
    <Header></Header>
    <MeetTheTeam></MeetTheTeam>
</template>

<style scoped></style>

<script lang="js">
import { defineComponent } from 'vue';
import Header from './components/Header.vue';
import MeetTheTeam from './components/MeetTheTeam.vue';


export default defineComponent({
    name: 'AboutUs',
    components: {
        Header,
        MeetTheTeam
    },
    created() {

    },
    data() {
        return {

        }
    },
    methods: {

    }

});
</script>