<!-- Login Screen 2024 -->

<template>
    <div class="login">
        <div class="login-background">
            <div class="login-grid">
                <div class="login-card">
                    <h1 class="login-title">{{ $t('sign.login') }}</h1>

                    <input class="input" :placeholder="$t('sign.email')" type="text" name="email" id="email"
                        v-model="email">

                    <input class="input" :placeholder="$t('sign.password')" type="password" name="password"
                        id="password" v-model="password" @keyup.enter="fetchUser">

                    <p v-if="errorMessage" class="wrong">{{ (errorMessage) }}</p>


                    <button class="login-user" @click="fetchUser">{{ $t('sign.login2') }}</button>

                    <br>

                    <!-- <router-link class="register-here" to="/signup">Do you forgot your password?</router-link> -->
                    <p class="noaccount"></p>

                    <button data-toggle="modal">
                        {{ $t('sign.forgotpass') }}
                    </button>



                    <p class="noaccount">{{ $t('sign.account') }}</p>

                    <router-link class="register-here" to="/signup">{{ $t('sign.registerhere') }}</router-link>
                    <p class="noaccount">{{ $t('sign.or') }}</p>

                    <!-- <button  @click="googleLogin" class="google-login">{{ $t('sign.google') }}</button> -->
                    <a href="http://x2025mirovision.francecentral.cloudapp.azure.com/v1/google/login"
                        @click="handleClick()" class="google-login"> {{ $t('sign.google') }}</a>

                </div>
            </div>
        </div>
    </div>

    <!-- MODAL CODE -->
    <div role="dialog" id="modal-example" aria-hidden="false" style="display: flex;"
        class="modal fixed top-0 left-0 z-50 w-screen h-screen bg-black/30 flex items-center flex-col justify-center p-6 fade"
        tabindex="-1">

        <article class="modal-content flex flex-col relative m-0 rounded-md bg-white sm:my-16"
            aria-labelledby="modal-title" aria-describedby="modal-body">
            <div class="bg-gray-100 flex items-center justify-center rounded-3xl">
                <div class="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full">
                    <div class="flex items-center space-x-2 mb-6">
                        <h1 class="text-xl font-semibold">{{ $t('sign.modal.title') }}</h1>
                    </div>
                    <p class="text-sm text-gray-600 mb-6">{{ $t('sign.modal.subtitle') }}</p>
                    <form id="change_password_form" class="space-y-6">

                        <div>
                            <label for="recover_email" class="text-sm font-medium text-gray-700 block mb-2">
                                {{ $t('sign.modal.email') }}
                            </label>
                            <input type="text" id="recover_email" v-model="recover_email" class="modal-textbox"
                                required>
                        </div>
                        <div>
                            <label for="recover_password_check" class="text-sm font-medium text-gray-700 block mb-2">{{
                        $t('sign.modal.confirmation') }}</label>
                            <input type="text" id="recover_password_check" v-model="recover_password_check"
                                class="modal-textbox" required>
                            <button type="button" @click="clearConfirmPassword"
                                class="text-xs text-blue-600 hover:underline mt-1">{{ $t('sign.modal.clear') }}</button>
                        </div>

                        <p v-if="modalErrorMsg" class="wrong">{{ (modalErrorMsg) }}</p>

                        <div class="flex justify-between">
                            <button data-dismiss="modal" type="button" class="
                                google-login">
                                {{ $t('sign.modal.discard') }}</button>
                            <button type="submit" @click="sendResetEmail"
                                class="
                                px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300">
                                {{ $t('sign.modal.submit') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </article>
    </div>


</template>

<script lang="js">
import { defineComponent } from 'vue';
import { useAuthStore } from "../../store/auth-store";
import { login, loginGoogle, forgotPassword } from '../../services/auth-service.js';
import { inject } from "vue";

import vue3GoogleLogin from 'vue3-google-login'

export default defineComponent({
    name: 'Login',
    mounted() {
        this.setupModal();
    },
    data() {
        return {
            email: '',
            password: '',
            user: '',
            recover_password_check: '',
            recover_email: '',
            incorrect: false,
            empty_fields: false,
            errorMessage: '',
            modalErrorMsg: '',
        };
    },
    methods: {
        showModal(modal) {

            console.log("Display Modal");

            modal.style.display = 'flex';
            setTimeout(() => {
                modal.classList.add('show');
            }, 100);
            modal.setAttribute('aria-hidden', 'false');
            document.body.style.overflow = 'hidden';
            document.body.classList.add('astroui-modal-open');
        },

        dismissModal(modal) {
            modal.classList.remove('show');
            setTimeout(() => {
                modal.style.display = 'none';
            }, 200);
            modal.setAttribute('aria-hidden', 'true');
            document.body.style.overflow = '';
            document.body.classList.remove('astroui-modal-open');

            const form = document.getElementById('change_password_form');
            form.reset();
        },

        getDismiss(buttonClose, modal) {
            buttonClose.addEventListener('click', () => {
                this.dismissModal(modal);
            });
        },

        setupModal() {
            const buttonClose = document.querySelectorAll('[data-dismiss="modal"]');
            const modal = document.querySelector('.modal');
            const trigger = document.querySelector('[data-toggle="modal"]');

            this.dismissModal(modal);

            buttonClose.forEach((buttonClose) => {
                this.getDismiss(buttonClose, modal);
            });

            trigger.addEventListener('click', () => {
                this.showModal(modal);
            });

            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape' && modal.classList.contains('show')) {
                    this.dismissModal(modal);
                }
            });
        },
        fetchUser() {
            this.errorMessage = "";
            if (this.email && this.password) {
                this.empty_fields = false;

                login(this.email, this.password).then((res) => {
                    localStorage.setItem("access_token", res.data.access_token);
                    localStorage.setItem("user_gmail", this.email);

                    if (this.authStore) {
                        this.authStore.update(true);
                    } else {
                        console.warn("authStore not available yet");
                    }

                    this.$emit('login', true);
                    window.location.href = "/"
                }).catch((error) => {
                    if (error.response) {
                        this.errorMessage = error.response.data.detail;
                    } else {
                        this.errorMessage = 'There has been an error, please try again later'
                    }
                });
            } else {
                this.errorMessage = "Please provide a username / password";
                this.incorrect = true;
            }
        },

        async handleClick() {
            if (this.$route.query.token) {
                localStorage.setItem('accessToken', this.$route.query.token);
                localStorage.setItem('userId', this.$route.query.id);
                this.$router.push('/')
            }
        },

        clearConfirmPassword() {
            const form = document.getElementById('change_password_form');
            form.reset();
        },

        async sendResetEmail(event) {
            this.modalErrorMsg = '';
            event.preventDefault();

            if (this.recover_email && this.recover_email == this.recover_password_check) {
                await forgotPassword(this.recover_email).then((res) => {
                }).catch((error) => {

                    console.error("ERROR recover email - ", error);
                })
            } else {
                this.modalErrorMsg = "Not valid input"
                console.error('ERROR - Email missing / The emails must be equal');
            }
        }
    },
})
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');


:root {
    --font-family: 'Inter', sans-serif;
}

html,
body {
    font-family: var(--font-family);
}

.login-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.login {
    display: flex;
    margin-top: 160px;
    align-items: center;
    justify-content: center;
}

.register-here {
    color: #0049b0;
    font-weight: bolder;
    margin-bottom: -1em;
}

.modal-card {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    padding: 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.login-card {
    background-color: white;
    border-radius: 2em;
    width: 30em;
    height: 40em;
    -webkit-box-shadow: 3px 5px 22px 3px rgba(0, 0, 0, 0.46);
    box-shadow: 3px 5px 22px 3px rgba(0, 0, 0, 0.46);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    rotate: 10deg;
    transition: all 1s;
    z-index: 10;
}

.input {
    border: 1px solid black;
    padding: 5px;
    margin-bottom: 1em;

    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    color: #111827;
    background-color: #ffffff;
}

.modal-textbox {
    padding: 0.5rem;
    display: block;
    border-radius: 0.5rem;
    border-width: 1px;
    color: #111827;
    width: 100%;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.login-button {
    background: #0049b0;
    padding: 10px 40px;
    border-radius: 0.5em;
    color: white;
    font-weight: bold;
    transition: all 0.5s;
}

.login-button:hover {
    padding: 10px 50px;
}

.login-user {
    display: inline-flex;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-right: 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background-color: #1D4ED8;
    transition: all 0.5s;
}

.login-user:hover {
    background-color: #1E40AF;
    padding: 12px 55px;
}

.google-login {
    padding: 10px 30px;
    margin-right: 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    border-width: 2px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    background-color: #ffffff;
    color: #0049b0;
}

.google-login:hover {
    background-color: #0049b0;
    color: white;
}

.login-background {
    width: 30em;
    height: 40em;
    background: #0049b0;
    border-radius: 2em;
    rotate: -10deg;
    transition: all 1s;
}

.noaccount {
    margin-top: 1em;
}

.wrong {
    color: red;
    padding-bottom: 15px;
}

@media (max-width: 1919px) {
    /* .left-section { */
    /*     display: none; */
    /* } */

    /* .left-section-margin { */
    /*     display: none; */
    /* } */
}

@media (max-width: 600px) {
    /* .left-section { */
    /*     display: none; */
    /* } */

    /* .left-section-margin { */
    /*     display: none; */
    /* } */

    .login-background {
        rotate: 0deg;
        width: 100%;
        height: auto;
        background: none;
    }


    .login-card {
        rotate: 0deg;
        width: 100%;
        height: auto;
        padding: 5% 10%;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .login {
        display: flex;
        margin-top: 30px;
        align-items: center;
        justify-content: center;
    }

    .login-button {
        font-size: 18px;
        padding: 15px 40px;
    }

    .input {
        font-size: 20px;
    }

    .wrong {
        font-size: 18px;
    }

    .noaccount,
    .register-here {
        font-size: 18px;
    }

    .google-login {
        font-size: 14px;
        margin-top: 10px;
        padding: 10px;
        border: 2px solid #0049b0;
        transition: all 0.5s;
    }

    @media (min-width: 1920px) {
        /* .left-section-margin { */
        /*     margin: 100px; */
        /* } */
    }

}
</style>
