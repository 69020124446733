import http from "../http-requests-area";
import axios from 'axios';

// performs a login using username and password.
export async function login(user, pass) {
    return await http.post('/v1/users/login', {
        username: user,
        password: pass,
        grant_type: "",
        scope: "",
        client_id: "",
        client_secret: ""
    }, { headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }});
}

// handles login using Google authentication.
export async function loginGoogle() {
    return http.get('v1/google/login', {
    });
}

//  performs user logout.
export async function logout() {
    return await http.get('/logs/logout');
}

//  registers a new user.
export async function register(username, email, pass) {
    return await http.post('/v1/users/register', {
        username: username,
        email: email,
        password: pass
    });
}

// uses axios.post to subscribe an email address to a MailerLite
export async function earlyAccess(email) {
    const endpoint = 'https://connect.mailerlite.com/api/subscribers';
    const authToken = process.env.VUE_APP_MAIL_TOKEN; // Replace with your actual authentication token

    const requestBody = {
        email: email,
        groups: [
            '88866055602046335' // ID for join beta group
        ]
    };

    axios.post(endpoint, requestBody, {
        headers: {
            'Authorization': `Bearer ${authToken}`
        }
    })
        .then(response => {
            //console.log('API response:', response.data);
            // Handle the response here
        })
        .catch(error => {
            console.error('API error:', error.response.data);
            // Handle the error here
        });
}

// retrieves the user's role.
export async function getRole() {
    try {
        const accessToken = localStorage.getItem('accessToken');
        const headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        const response = await http.get('/v1/role/me', {
            headers: headers
        });

        //console.log("User Permission -> " + JSON.stringify(response.data, null, 1));
        let userRole = JSON.stringify(response.data[0].name, null, 1);
        //console.log("(get role) User Role -> " + userRole);
        localStorage.setItem('userRole', this.$route.query.token);

        return response.data;
    } catch (error) {
        console.error('Error Getting Role: ', error);
        throw error;
    }
}

// changes the user's password.
export async function changePassword(token, pass) {
    return await http.post('/v1/users/recover_password/', { // Updated endpoint
        token: token,
        new_password: pass
    }, { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`  // Add Authorization header 
    }});
}

// initiates password recovery by sending a reset link.
export async function forgotPassword(email) {
    return await http.post('/v1/users/forgot_password/' + email, '', {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }