<template>
    <section class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-12">
        <div class="text-center pb-12">
            <!-- <h2 class="text-base font-bold text-indigo-600">
                We have the best equipment
            </h2> -->
            <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900">
                {{$t('header.team')}}
            </h1>

        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
                <div class="mb-8">
                    <img class="object-center object-cover rounded-full h-36 w-36"
                        src="../assets/pol.jpeg"
                        alt="photo">
                </div>


                <div class="text-center">
                    <p class="text-xl text-gray-700 font-bold mb-2">Pol Aragonés</p>
                    <p class="text-base text-gray-400 font-normal">Frontend Developer</p>
                </div>

                <div class="-mx-2 mt-3 flex">
                    <a href="https://www.linkedin.com/in/polaragones/"
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="LinkedIn">
                        <i class="pi pi-linkedin h-6 w-6 fill-current" style="font-size: 1.4rem"></i>

                    </a>

                    <a href="https://github.com/    "
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="Github">
                        <i class="pi pi-github h-6 w-6 fill-current" style="font-size: 1.4rem"></i>
                    </a>
                </div>

            </div>
            <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
                <div class="mb-8">
                    <img class="object-center object-cover rounded-full h-36 w-36"
                        src="../assets/javi.jpeg"
                        alt="photo">
                </div>
                <div class="text-center">
                    <p class="text-xl text-gray-700 font-bold mb-2">Javier Sánchez</p>
                    <p class="text-base text-gray-400 font-normal">Backend Developer</p>
                </div>

                <div class="-mx-2 mt-3 flex">
                    <a href="https://www.linkedin.com/in/javier-sanchez-castro/"
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="LinkedIn">
                        <i class="pi pi-linkedin h-6 w-6 fill-current" style="font-size: 1.4rem"></i>

                    </a>

                    <a href="https://github.com/JavierSanchezCastro"
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="Github">
                        <i class="pi pi-github h-6 w-6 fill-current" style="font-size: 1.4rem"></i>
                    </a>
                </div>
            </div>
            <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
                <div class="mb-8">
                    <img class="object-center object-cover rounded-full h-36 w-36"
                        src="../assets/jose.jpeg"
                        alt="photo">
                </div>
                <div class="text-center">
                    <p class="text-xl text-gray-700 font-bold mb-2">Jose Antonio Rodríguez</p>
                    <p class="text-base text-gray-400 font-normal">IA Developer</p>
                </div>

                <div class="-mx-2 mt-3 flex">
                    <a href="https://www.linkedin.com/in/jose-assalone/"
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="LinkedIn">
                        <i class="pi pi-linkedin h-6 w-6 fill-current" style="font-size: 1.4rem"></i>

                    </a>

                    <a href="https://github.com/    "
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="Github">
                        <i class="pi pi-github h-6 w-6 fill-current" style="font-size: 1.4rem"></i>
                    </a>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
                <div class="mb-8">
                    <img class="object-center object-cover rounded-full h-36 w-36"
                        src="../assets/miguel.jpeg"
                        alt="photo">
                </div>


                <div class="text-center">
                    <p class="text-xl text-gray-700 font-bold mb-2">Miguel Arnalot</p>
                    <p class="text-base text-gray-400 font-normal">Mobile Developer</p>
                </div>

                <div class="-mx-2 mt-3 flex">
                    <a href="https://www.linkedin.com/in/miguel-arnalot/"
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="LinkedIn">
                        <i class="pi pi-linkedin h-6 w-6 fill-current" style="font-size: 1.4rem"></i>

                    </a>

                    <a href="https://github.com/    "
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="Github">
                        <i class="pi pi-github h-6 w-6 fill-current" style="font-size: 1.4rem"></i>
                    </a>
                </div>

            </div>
            <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
                <div class="mb-8">
                    <img class="object-center object-cover rounded-full h-36 w-36"
                        src="../assets/arent.jpeg"
                        alt="photo">
                </div>
                <div class="text-center">
                    <p class="text-xl text-gray-700 font-bold mb-2">Arent Dollapaj</p>
                    <p class="text-base text-gray-400 font-normal">Backend Developer</p>
                </div>

                <div class="-mx-2 mt-3 flex">
                    <a href="https://www.linkedin.com/in/arent-dollapaj-326942213/"
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="LinkedIn">
                        <i class="pi pi-linkedin h-6 w-6 fill-current" style="font-size: 1.4rem"></i>

                    </a>

                    <a href="https://github.com/    "
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="Github">
                        <i class="pi pi-github h-6 w-6 fill-current" style="font-size: 1.4rem"></i>
                    </a>
                </div>
            </div>
            <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
                <div class="mb-8">
                    <img class="object-center object-cover rounded-full h-36 w-36"
                        src="../assets/joaquin.jpeg"
                        alt="photo">
                </div>
                <div class="text-center">
                    <p class="text-xl text-gray-700 font-bold mb-2">Joaquin Delgado</p>
                    <p class="text-base text-gray-400 font-normal">IA Developer</p>
                </div>

                <div class="-mx-2 mt-3 flex">
                    <a href="https://www.linkedin.com/in/joaquin-delgado-carrascal/"
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="LinkedIn">
                        <i class="pi pi-linkedin h-6 w-6 fill-current" style="font-size: 1.4rem"></i>

                    </a>

                    <a href="https://github.com/    "
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="Github">
                        <i class="pi pi-github h-6 w-6 fill-current" style="font-size: 1.4rem"></i>
                    </a>
                </div>
            </div>
        </div>

        <div class="flex flex-col items-center justify-center sm:flex-row">
            <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
                <div class="mb-8">
                    <img class="object-center object-cover rounded-full h-36 w-36"
                        src="../assets/claudio.jpeg"
                        alt="photo">
                </div>


                <div class="text-center">
                    <p class="text-xl text-gray-700 font-bold mb-2">Claudio Martínez</p>
                    <p class="text-base text-gray-400 font-normal">Frontend Developer</p>
                </div>

                <div class="-mx-2 mt-3 flex">
                    <a href="https://www.linkedin.com/in/claudio-mart%C3%ADnez-melero/"
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="LinkedIn">
                        <i class="pi pi-linkedin h-6 w-6 fill-current" style="font-size: 1.4rem"></i>

                    </a>

                    <a href="https://github.com/    "
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="Github">
                        <i class="pi pi-github h-6 w-6 fill-current" style="font-size: 1.4rem"></i>
                    </a>
                </div>

            </div>
            <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
                <div class="mb-8">
                    <img class="object-center object-cover rounded-full h-36 w-36"
                        src="../assets/marata.jpeg"
                        alt="photo">
                </div>
                <div class="text-center">
                    <p class="text-xl text-gray-700 font-bold mb-2">Adrià Marata</p>
                    <p class="text-base text-gray-400 font-normal">Backend Developer</p>
                </div>

                <div class="-mx-2 mt-3 flex">
                    <a href="https://www.linkedin.com/in/adriamarata/"
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="LinkedIn">
                        <i class="pi pi-linkedin h-6 w-6 fill-current" style="font-size: 1.4rem"></i>

                    </a>

                    <a href="https://github.com/    "
                        class="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
                        aria-label="Github">
                        <i class="pi pi-github h-6 w-6 fill-current" style="font-size: 1.4rem"></i>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped></style>

<script lang="js">
import { defineComponent } from 'vue';
import 'primeicons/primeicons.css'

export default defineComponent({
    name: 'MeetTheTeam',
    created() {
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {

    },
    components: {

    }

});
</script>