<template>
    <div class="g">
        <div class="b1">
            <div class="b2-background">
                <div class="b2-card">
                    <p class="title1">Miro Vision </p>
                    <p class="description">"Democratizing The Computer Vision"</p>
                    <div class="button-box">
                        <button class="myButton">. . . .</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>


<style scoped>
.button-box {
    margin-top: 10%;
}
.b2-card {
    background-color: white;
  border-radius: 0.2em;
  width: 30em;
  height: 40em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  rotate: 10deg;
  transition: all 1s;
  z-index: 10;
}
.b2-background {
  position: absolute;
  width: 30em;
  height: 40em;
  background: rgb(240, 72, 6);
  background: linear-gradient(90deg, #403D39 57%, rgba(240, 72, 6) 57%);
  border-radius: 0.2em;
  rotate: -10deg;
  transition: all 1s;
}

.b2-background:hover {
  rotate: 30deg;
}
.b2-background:hover > .b2-card {
  rotate: -30deg;
}
.myButton {
    background:linear-gradient(to bottom, #574340 5%, #000000 100%);
    background-color:#574340;
    border-radius:0.2em;
    border:1px solid #000000;
    display:inline-block;
    cursor:pointer;
    color:#ffffff;
    font-family:Arial;
    font-size:15px;
    font-weight:bold;
    padding:6px 24px;
    text-decoration:none;
    text-shadow:0px 1px 0px #000000;
}
.myButton:hover {
    background:linear-gradient(to bottom, #000000 5%, #574340 100%);
    background-color:#000000;
}
.myButton:active {
    position:relative;
    top:1px;
}
.cv-image {
    width: 35em;
    height: 35em;
}
.description{
    font-style: italic;
    font-size: 22px;
    margin-top: 2%;
}
.title1{
    font-style: italic;
    line-height:1.1em;
    font-size:80px;
}
.g {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.b1 {
    flex-direction: column;
    justify-self: center;
    justify-content: center;
}
</style>