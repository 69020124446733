<template>
    <div class="flip-card" :class="{ 'is-expanded': isExpanded }">
        <div class="flip-card-inner">
            <div class="flip-card-front">
                <p class="model-name">{{ name }}</p>
                <p class="model-desc">{{ desc }}</p>
                <button class="button_1" @click="createProjectFrom">Create Project from Model</button>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    name: 'ModelCard',
    props: {
        name: String,
        desc: String,
        pic: String,
        uuid: String
    },
    data() {
        return {
            isExpanded: false,
        };
    },
    methods: {
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
        },
        createProjectFrom() {
            //console.log(this.uuid);
            window.location.href = `/Platform?create=true&uuid=${this.uuid}`;
        }
    }
}
</script>





<style scoped>
.button_1 {
display: inline-flex; 
padding-top: 0.75rem;
padding-bottom: 0.75rem; 
padding-left: 3rem;
padding-right: 3rem; 
margin-left: 1.5rem;
margin-right: 1.5rem; 
justify-content: center; 
align-items: center; 
border-radius: 0.5rem; 
font-size: 1rem;
line-height: 1.5rem; 
font-weight: 500; 
text-align: center; 
color: #ffffff; 
background-color: #1D4ED8;
}

.flip-card {
    background-color: transparent;
    width: 320px;
    height: 320px;
    margin-top: 2em;
    transition: all 0.3s ease;
    overflow: hidden;
    border-color: black;
    border-width: 1px;
    border-radius: 0.5rem; 
}

.flip-card.is-expanded {
    height: 500px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 0.2em;
}

.flip-card-front {
    background-color: white;
    color: black;
    border: 1px solid black;
}

.flip-card-back {
    background: linear-gradient(90deg, rgba(82,183,136,1) 0%, rgba(64,145,108,1) 57%);
    color: white;
    transform: rotateY(180deg);
}

.model-name {
    font-weight: bolder;
    font-size: 35px;
    color: black;
    margin: 0 10px;
}

.model-desc {
    margin: 10px;
}

.subscribe {
    background-color: #0049b0;
    color: white;
    padding: 10px 30px;
    margin-top: 20px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
}

.subscribe:hover {
    background-color: #003680;
}

.select-model:hover {
    padding: 10px 50px;
}


</style>

  