<template>
    <!-- FEATURES -->
    <section class="bg-blueGray-200 -mt-24">
        <div class="container mx-auto px-6">
            <div class="mt-6 md:flex md:items-center md:justify-between">
                <div>
                    <h1 class="text-3xl font-semibold capitalize text-gray-800 dark:text-white lg:text-4xl">{{$t('landing.features')}}
                    </h1>

                    <div class="mx-auto mt-6 flex">
                        <span class="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                        <span class="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                        <span class="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mx-auto px-4">
            <div class="flex flex-wrap">
                <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                    <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                        <div class="px-4 py-5 flex-auto">
                            <div
                                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                                <i class="fas fa-award"></i>
                            </div>
                            <h6 class="text-xl font-semibold">{{$t('landing.setup')}}</h6>
                            <p class="mt-2 mb-4 text-blueGray-500">
                                {{$t('landing.setup_text')}} 
                            </p>
                        </div>
                    </div>
                </div>
                <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                    <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                        <div class="px-4 py-5 flex-auto">
                            <div
                                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-600	">
                                <i class="fas fa-retweet"></i>
                            </div>
                            <h6 class="text-xl font-semibold">{{$t('landing.simple')}}</h6>
                            <p class="mt-2 mb-4 text-blueGray-500">
                                {{$t('landing.simple_text')}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                    <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                        <div class="px-4 py-5 flex-auto">
                            <div
                                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                                <i class="fas fa-award"></i>
                            </div>
                            <h6 class="text-xl font-semibold">{{$t('landing.ready')}}</h6>
                            <p class="mt-2 mb-4 text-blueGray-500">
                                {{$t('landing.ready_text')}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<style scoped>

</style>

<script lang="js">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Features',
    created() {
    },
    data() {
        return {
           
        }
    },
    methods: {
        
    }

});
</script>