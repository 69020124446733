<template>
    <!-- <div class="switch">
            {{ $t('select') }}
            <div class="options">
                <button class="lang" @click="changeLang('en')"> <lang-flag iso="en" :squared="false" title="English"/></button>
                <button class="lang" @click="changeLang('es')">  <lang-flag iso="es" :squared="false"/></button>
                <button class="lang" @click="changeLang('fr')">  <lang-flag iso="fr" :squared="false"/></button>
            </div>
        </div> -->

    <div class="relative inline-block">
        <!-- {{ $t('select') }} -->
        <button @click="toggleDropdown" type="button"
            class="focus:outline-none rounded-lg bg-white px-3 py-2 text-left shadow-sm flex items-center justify-center" style="min-width: 130px;">
            {{ selectedLanguage.name }}
            <lang-flag :iso="getIsoCode(selectedLanguage.code)" :squared="false" />

            <!-- <svg class="w-4 h-4 ml-1 fill-current" viewBox="0 0 20 20" xmlns="http:
                <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l4.293-4.293a1 1 0 011.414 1.414l-6 6a1 1 0 01-1.414-1.414L5.293 7.293z"
                    clip-rule="evenodd"></path>
            </svg> -->
        </button>

        <transition name="language-dropdown">
            <ul v-show="isOpen" @click.away="isOpen = false"
                class="absolute z-50 font-medium text-gray-700 bg-white rounded-lg shadow-md overflow-hidden mt-1 flex items-center justify-center flex-col" style="min-width: 130px;">
                <li @click="changeLanguage('en')">
                    <a href="#" class="block px-4 py-2 hover:bg-gray-100">
                        English
                        <lang-flag iso="en" :squared="false" />
                    </a>
                </li>
                <li @click="changeLanguage('es')">
                    <a href="#" class="block px-4 py-2 hover:bg-gray-100">
                        Spanish
                        <lang-flag iso="es" :squared="false" />
                    </a>
                </li>
                <li @click="changeLanguage('fr')">
                    <a href="#" class="block px-4 py-2 hover:bg-gray-100">
                        French
                        <lang-flag iso="fr" :squared="false" />
                    </a>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import LangFlag from 'vue-lang-code-flags';



export default defineComponent({
    name: 'Switcher',
    components: {
        LangFlag
    },
    data() {
        return {
            items: [],
            selected: -1,
            model: "",

            selectedLanguage: { code: 'en', name: 'English' },
            isOpen: false,
        };
    },
    methods: {
        changeLang(language) {
            this.$i18n.locale = language
        },
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        changeLanguage(code) {
            this.selectedLanguage = { code, name: code === 'en' ? 'English' : (code === 'es' ? 'Spanish' : 'French') };
            this.$i18n.locale = code;
        },
        getIsoCode(code) {

            const isoMap = {
                en: 'en',
                es: 'es',
                fr: 'fr',

            };
            return isoMap[code] || 'unknown';
        },
    },
})
</script>

<style scoped>
.switch {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #0049b0;
    font-weight: bold;
    cursor: pointer;
    margin-right: 10px;
    min-height: 42px;
    min-width: 140px;
}

.switch:hover>.options {
    display: block;
    display: flex;
    flex-direction: column;
}

.options {
    display: flex;
    position: fixed;
    flex-direction: column;
    display: none;
    background-color: white;
    padding: 5px;
    border-bottom: 3px solid #0049b0;
}

.lang {
    margin-bottom: 3px;
}

.lang:hover {
    color: black;
}
</style>