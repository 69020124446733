  import { createRouter, createWebHistory } from 'vue-router'
  import Login from '../views/Authentification/Login.vue';
  import Logout from '../views/Authentification/Logout.vue';
  import Signup from '../views/Authentification/Signup.vue';
  import Models from '../views/Models.vue';
  import Launch from '../views/Launch.vue';
  import Cameras from '../views/Cameras.vue';
  import Logs from '../views/Logs.vue';
  import authGoogle from '../views/GoogleRedirect.vue'
  import ApkPage from '../views/Apk.vue';
  import Test from '../views/Test.vue';
  import TryModel from '../views/TryModel.vue'
  import ContactUs from '../views/ContactUs.vue'
  import Platform from '../views/Platform.vue'
  import Admin from '../views/Admin.vue'
  import Myprofile from '../views/MyProfile.vue'
  import RecoverPassword from '../views/RecoverPassword.vue'
  import AboutUs from '../components/Screens/AboutUs/AboutUs.vue'
  import AdminPanel from '../components/Screens/AdminPanel/AdminPannel.vue'
  import Validate from '../components/LandingPage/components/Validate.vue';
  // import VueFaqAccordion from '../views/VueFaqAccordion.vue'


  import InProgress from '../components/Message/InProgress.vue'
  import { getRole } from '../services/auth-service';

  const routes = [
    {
      path: '/validateuser',
      name: 'validateUser',
      component: Validate
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/authGoogle',
      name: 'authGoogle',
      component: authGoogle
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup
    },
    {
      path: '/models',
      name: 'models',
      component: Models,
      meta: { requiresAuth: false }
    },
    {
      path: '/',
      name: 'launch',
      component: Launch
    },
    {
      path: '/cameras',
      name: 'cameras',
      component: Cameras,
      meta: { requiresAuth: true, roles: ['ADMIN'] }
    },
    {
      path: '/contactUs',
      name: 'ContactUs',
      component: ContactUs
    },
    {
      path: '/platform',
      name: 'Platform',
      component: Platform
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin,
      meta: { requiresAuth: true, roles: ['ADMIN'] }
    },
    {
      path: '/adminPanel',
      name: 'AdminPanel',
      component: AdminPanel,
      meta: { requiresAuth: true, roles: ['ADMIN'] }
    },
    {
      path: '/myprofile',
      name: 'myprofile',
      component: Myprofile,
      // meta: { requiresAuth: true, roles: ['ADMIN'] }
    },
    {
      path: '/recoverpassword',
      name: 'recoverpassword',
      component: RecoverPassword,
      // meta: { requiresAuth: true, roles: ['ADMIN'] }
    },
    {
      path: '/AboutUs',
      name: 'AboutUs',
      component: AboutUs,
      // meta: { requiresAuth: true, roles: ['ADMIN'] }
    },
    // {
    //   path: '/FaQ',
    //   name: 'FaQ',
    //   component: VueFaqAccordion
    // },
    {
      path: '/InProgress',
      name: 'InProgress',
      component: InProgress,
      // meta: { requiresAuth: true, roles: ['ADMIN'] }
    },

  ]

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

  router.beforeEach((to, from, next) => {
    // getRole(); 
    const isAuthenticated = (localStorage.getItem("access_token") ? true : false);
    const userRole = localStorage.getItem('userRole');
    // //console.log("(index) User Role " + userRole);
  
    //console.log("to.meta.requiresAuth " + to.meta.requiresAuth + " isAuthenticated " + isAuthenticated);
      if (to.meta.requiresAuth == true && !isAuthenticated) {
        //console.log("Login is required")
        next('/login');
      // } else if (to.meta.roles && localStorage.getItem('userRole') != to.meta.roles) {
      //   //console.log("-> Incorrect Level of Permission " + to.meta.roles + " - " + localStorage.getItem('userRole'))
      //   next('/login');
      } else 
        next();
  });

  export default router
