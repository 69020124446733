<template>
    <div class="model-page">
        <h1 class="page-title">{{ $t('model.gallery') }}</h1>
        <p class="page-description">
            {{ $t('model.choose') }}
        </p>

        <ModelCarrusel></ModelCarrusel>

        <div class="center">
            <MarkdownRenderer  :source="markdownTitle" />
            <MarkdownRenderer  :source="markdownDescription" />
            <MarkdownRenderer  :source="markdownAutor" />
            <MarkdownRenderer  :source="markdownLink" />
        </div>

        <div class="admin-upload">
            <h2 class="upload-title">{{ $t('model.admin') }}</h2>
            <button class="button_1" @click="show = !show">
                <p class="add-model-text">{{ $t('model.import') }}</p>
            </button>
            <div class="upload" v-if="show">
                <input class="input_text" type="text" placeholder="model name" v-model="name">
                <input class="input_text" type="text" placeholder="model description" v-model="descr">
                <label for="file-upload" class="custom-file-upload">
                    {{ $t('model.upload') }}
                </label>
                <input id="file-upload" type="file" @change="handleFile" />
                <button class="button_1" @click="push">{{ $t('model.upbtn') }}</button>
            </div>
        </div>


        <div class="models">
            <div v-for="(model, index) in models" :key="index">
                <ModelCard :name="model.name" :desc="model.desc" :pic="''" :uuid="model.id"></ModelCard>
            </div>
        </div>
    </div>
</template>


<style scoped>
/* Contenedor principal que alinea los elementos vertical y horizontalmente */
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 20px;
  
  height: 300px;
}

.button_1 {
    display: inline-flex;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-right: 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background-color: #1D4ED8;
}

.input_text {
    border: 1px solid black;
    padding: 5px;
    margin-bottom: 1em;

    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    color: #111827;
    background-color: #ffffff;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    color: white;
    background-color: #0049b0;
    padding: 4px 9px;
    border-radius: 0.2em;
}

.page-title,
.page-description {
    text-align: center;
    margin: 20px;
}

.page-title {
    font-size: 2em;
}

.page-description {
    font-size: 1em;
    max-width: 80%;
    margin: 20px auto;
}

.close {
    position: absolute;
    top: 2%;
    left: 2%;
    font-size: 20px;
}

input {
    border: 1px solid black;
}

.upload-model {
    background-color: #0049b0;
    color: white;
    padding: 10px 30px;
}

.upload {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    margin-bottom: 2em;
    width: fit-content;
    box-sizing: border-box;
    gap: 15px;
    z-index: 9;
    background-color: white;
    border: 1px solid black;
    padding: 20px;
    border-radius: 0.5rem;
}

.models {
    gap: 50px;
    flex-wrap: wrap;
    display: flex;
    margin: 80px 2% 0;
}

.add-model {
    background-color: #0049b0;
    color: white;
    border-radius: 0.2em;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    border: none;
    cursor: pointer;
}


.admin-upload {
    text-align: center;
    margin-top: 5em;
    margin-left: 2em;
    margin-right: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.upload-title {
    margin-bottom: 20px;
    font-size: 18px;
}

@media (max-width: 600px) {

    .models,
    .admin-upload {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .page-description {
        max-width: 90%;
    }
}
</style>


<script lang="js">
import { defineComponent } from 'vue';
import { redditAuth } from '../logServices.js';
import { getModels, createModel } from '../userService.js';

import ModelCard from '../components/ModelCard';
import SelectModel from '../components/SelectModel';
import ModelCarrusel from './modelCarrusel/ModelCarrusel.vue';
import MarkdownRenderer from './markdownReader/MarkdownReader.vue'

export default defineComponent({
    name: 'ModelsPage',
    data() {
        return {
            redditClient: '',
            redditSecret: '',
            models: [],
            show: false,
            descr: '',
            name: '',
            selectedFile: null,

            markdownTitle: `**Kwai-Kolors/Kolors**`,
            markdownAutor: `*autor* **Kolors Team**`,
            markdownDescription: `*Kolors: Effective Training of Diffusion Model for Photorealistic Text-to-Image Synthesis*`,
            markdownLink: `[Link to Repo](https://huggingface.co/Kwai-Kolors/Kolors)`,            
        };
    },
    mounted() {
        this.getModels();
    },
    components: {
        ModelCard,
        SelectModel,
        ModelCarrusel,
        MarkdownRenderer
    },
    methods: {
        auth() {
            localStorage.setItem("redditClient", this.redditClient);
            localStorage.setItem("redditSecret", this.redditSecret);
            window.open(`https://www.reddit.com/api/v1/authorize?
							client_id=${this.redditClient}&response_type=code
							&state=random-string
							&redirect_uri=http://localhost:8081/reddit/callback
							&duration=permanent&scope=identity,submit,save`,
                "_self");
        },
        async getModels() {
            const res = await getModels();
            for (const model of res.data) {
                this.models.push({
                    name: model.name,
                    desc: model.description,
                    id: model.uuid,
                });
            }
        },
        create() {
            createModel(this.name, this.descr, this.selectedFile).then((res) => {
                //console.log(res)
            })
                .catch((error) => {
                    //console.log(error)
                });
        },
        open() {
            this.show = true;
        },
        close() {
            this.show = false;
        },
        push() {
            this.models.push({
                name: this.name,
                desc: this.descr,
                id: "",
            });
            this.create();
            this.close();
        },
        handleFile(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedFile = file;
            }
        }
    }
})
</script>