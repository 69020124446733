<template>
    <div class="connect-camera">
    <!-- QR code -->
    <div class="qr-container">
      <img class="qrImage" src="../assets/qr.jpeg" alt=""/>  
    </div>
    <!-- test - testbox - button -->
    <div class="info-container">
      <div class="cam-id">Cam ID</div>
        <input class="cam-field" v-model=cameraId type="text" placeholder="Enter Cam ID" autofocus/>
      <div class="">
        <Button class="cam-button" label="Connect" @click="updateId" />
      </div>
    </div>
  </div>
</template>
  
<style scoped>
.cam-field{
    border: 2px solid black;
    border-radius: 7px;
    padding: 5px;
}

.cam-button {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 10px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 35px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.cam-button:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.cam-button:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.cam-button:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.cam-button:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.cam-button:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.cam-button:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.cam-button:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.cam-button:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.connect-camera {
  display: flex;
  padding: 30px;
  border-radius: 2em;
  background-color: white;
}

.info-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 20px;
}
.qrImage {
  width: 15em;
  height: 15em;
}
</style>
  
<script lang="js">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ConnectCamera',
    data() {
        return {
            cameraId: ''
        };
    },
    mounted() {
    },
    components: {
    },
    methods: {
        updateId: function () {
            this.$emit('newCameraId', this.cameraId);
        }
    }
})
</script>