<template>
    <div class="platform">
        <router-link to="/platform" class="admin">Projects</router-link>
        <div class="empty" v-if="loggedin == false">
            <Login v-on:login="login"></Login>
        </div>
        <div class="panel" v-if="loggedin == true">
            <div class="card">
                <h1>Upload Models</h1>
                <input type="file">
                <button class="create">Upload</button>
            </div>
        </div>
    </div>
</template>
  
<style scoped>
.card {
    background-color: white;
    padding: 50px;
    border: 1px solid gray;
    border-radius: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.container {
    width: fit-content;
    margin-right: 30px;
    margin-bottom: 20px;
}
.panel {
    padding-top: 50px;
    margin-left: 30px;
    display: flex;
    align-items: flex-start;
}
.platform {
    background-color: #EEE;
    width: 100%;
    height: 100%;
}
.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    flex-direction: column;
}
.empty > h1 {
    font-size: 30px;
    color: #0049b0;
    font-weight: 600;
}
.create {
    margin-top: 50px;
    background-color: #0049b0;
    border-radius: 0.1em;
    padding: 10px 40px;
    color: white;
    transition: all 0.5s;
}
.create:hover {
    background-color: black;
}

.admin {
    display: inline-block;
    margin-top: 70px;
    background-color: #0049b0;
    border-radius: 0.1em;
    padding: 10px 40px;
    color: white;
    transition: all 0.5s;
}
.admin:hover {
    background-color: black;
}
</style>
  
<script lang="js">
import { defineComponent } from 'vue';
import Project from '../components/Platform/Project';
import Login from './Authentification/Login';

export default defineComponent({
    name: 'Platform',
    data() {
        return {
            loggedin: false
        };
    },
    mounted() {
    },
    components: {
        Project,
        Login
    },
    methods: {
        login: function () {
            this.loggedin = true;
        },
        setLogin: function (ev) {
            this.loggedin = ev;
        },
    }
})
</script>