<template>
  <div class="markdown-renderer">
    <div v-html="compiledMarkdown"></div>
  </div>
</template>

<script lang="js">
import { defineComponent, computed } from 'vue';
import MarkdownIt from 'markdown-it';
import MarkdownItAbbr from 'markdown-it-abbr';
import MarkdownItAnchor from 'markdown-it-anchor';
import MarkdownItFootnote from 'markdown-it-footnote';
import MarkdownItHighlightjs from 'markdown-it-highlightjs';
import MarkdownItSub from 'markdown-it-sub';
import MarkdownItSup from 'markdown-it-sup';
import MarkdownItTasklists from 'markdown-it-task-lists';
import MarkdownItTOC from 'markdown-it-toc-done-right';

const markdown = new MarkdownIt()
  .use(MarkdownItAbbr)
  .use(MarkdownItAnchor)
  .use(MarkdownItFootnote)
  .use(MarkdownItHighlightjs)
  .use(MarkdownItSub)
  .use(MarkdownItSup)
  .use(MarkdownItTasklists)
  .use(MarkdownItTOC);

export default defineComponent({
  name: 'MarkdownRenderer',
  props: {
    source: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const compiledMarkdown = computed(() => markdown.render(props.source));
    
    return {
      compiledMarkdown
    };
  }
});
</script>

<style scoped>
.markdown-renderer {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.markdown-renderer h1,
.markdown-renderer h2,
.markdown-renderer h3,
.markdown-renderer h4,
.markdown-renderer h5,
.markdown-renderer h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: bold;
}

.markdown-renderer p {
  margin: 0 0 1em 0;
}

.markdown-renderer a {
  color: #007bff;
  text-decoration: none;
}

.markdown-renderer a:hover {
  text-decoration: underline;
}

.markdown-renderer pre {
  background-color: #f8f9fa;
  padding: 1em;
  border-radius: 0.25em;
  overflow-x: auto;
}

.markdown-renderer code {
  background-color: #f8f9fa;
  padding: 0.2em 0.4em;
  border-radius: 0.25em;
}

.markdown-renderer ul,
.markdown-renderer ol {
  margin: 0 0 1em 1.5em;
}

.markdown-renderer blockquote {
  border-left: 4px solid #e9ecef;
  padding-left: 1em;
  margin: 0 0 1em 0;
  color: #6c757d;
}
</style>
