<template>
    <div class="validate">
        validate
    </div>

</template>

<style scoped></style>

<script lang="js">
import { defineComponent } from 'vue';
import { verifyUser } from '../../../userService';

export default defineComponent({
    name: 'Validate',
    mounted() {
        verifyUser(this.$route.query.token)
    },
    data() {
        return {

        }
    },
    methods: {

    }

});
</script>