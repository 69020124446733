import axios from 'axios';
import http from "./http-requests-area";

// deprecated funtion 
export async function getUserInfo() {
    let token = localStorage.getItem("token")
    return await axios({
        method: 'GET',
        url: 'http://localhost:8000/users/info',
        headers: {
            'accept': "application/json",
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
}

// get the permissions of an expecific user
export async function getUserPermissionByUuid(userUuid) {
    const url = `/v1/permissions/${userUuid}`;

    const response = await http.get(url, {
        headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
    });

    return response.data; 
}

// add permission to an expecific user
export async function addUserPermission(permissionId, userUuid) {
    const url = `/v1/permissions/${permissionId}/add_user/${userUuid}`;
  
    const response = await http.post(url, "", {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    return response.data; 
  }

// delete permissions to an expecific user
export async function deleteUserPermission(permissionId, userUuid) {
    const url = `/v1/permissions/${permissionId}/delete_user/${userUuid}`;
    const response = await http.delete(url, {
        headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
    });

    return response.data; 
}

// get all user info
export async function getAllUserInfo() {
    return await http.get(`/v1/users/`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'accept': 'application/json',
        }
    });
}

// get all permissions existing
export async function getAllPermissions() {
    return await http.get(`/v1/permissions/`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'accept': 'application/json',
        }
    });
}

// create a project you should provide a name, description and model
export async function createProj(name, desc, model, action) {

    return await http.post('/v1/projects', {
        name: name,
        description: desc,
        models_uuid: [model],
        actions_uuid: action,
    }, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'Content-Type': 'application/json',
        }
    });
}

// execute a existing project
export async function runProj(uuid, imageFile) {
    const formData = new FormData();
    formData.append('image', imageFile);

    return await http.post(`/v1/projects/${uuid}/run`, formData, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'Content-Type': 'multipart/form-data'
        }
    });
}

// delete an avaible project
export async function deleteProj(uuid) {
    return await http.delete(`/v1/projects/${uuid}/delete`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'Content-Type': 'application/json',
        }
    });
}

// get the existing projects
export async function getProj() {
    return await http.get(`/v1/projects/`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'accept': 'application/json',
        }
    });
}
// get all the existing model
export async function getModels() {
    return await http.get(`/v1/models/`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'accept': 'application/json',
        }
    });
}

// create a new model
export async function createModel(name, desc, file, images = []) {
    const formData = new FormData();
    formData.append('md_file', file);
    formData.append('md_images', images);
    return await http.post(`/v1/models/?name=${name}&description=${desc}`, formData, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        }
    });
}

export async function createAction(name, desc, obj, trgr, tms, react, dest, msg) {
    return await http.post('/v1/action/', {
        name: name,
        description: desc,
        object: obj,
        trigger: trgr,
        times: Number(tms),
        reaction: react,
        destination: dest,
        msg: msg,
    }, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'Content-Type': 'application/json',
        }
    });
}

export async function verifyUser(tkn) {
    return await http.post(`/v1/users/verify?token=${tkn}`, {
        headers: {
            'accept': 'application/json',
        },
    });
}

export async function getActions() {
    return await http.get(`/v1/action`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'accept': 'application/json',
        }
    });
}

export async function deleteAction(uuid) {
    return await http.delete(`/v1/actions/${uuid}/delete`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            'Content-Type': 'application/json',
        }
    });
}