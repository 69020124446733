<template>
    <div class="model-container">
        <div class="model-select" v-for="(item, index) in items" :key="item.id">
            <button @click="selectModel(index, item)" :class="index === selected ? 'selected' : 'no-selected'">{{ item.model }}</button>
        </div>
        <button @click="modelChosen" class="button_1">CONFIRM</button>
    </div>
</template>
  
<script>
export default {
    name: 'ModelSelect',
    props: {
    },
    data() {
        return {
            items: [],
            selected: -1,
            model: ""
        };
    },
    methods: {
        selectModel(index, item) {
            this.selected = index;
            this.model = item.id;
        },
        modelChosen: function () {
            this.$emit("newModel", this.model);
        }
    },
    mounted() {
        this.items.push({ id: 1, model: "object recognition" });
        this.items.push({ id: 2, model: "hotdog recognition" });
    }
}
</script>

<style scoped>
.button_1 {
display: inline-flex; 
padding-top: 0.75rem;
padding-bottom: 0.75rem; 
padding-left: 3rem;
padding-right: 3rem; 
margin-right: 0.75rem; 
justify-content: center; 
align-items: center; 
border-radius: 0.5rem; 
font-size: 1rem;
line-height: 1.5rem; 
font-weight: 500; 
text-align: center; 
color: #ffffff; 
background-color: #1D4ED8; 
}

.selected {
    padding: 10px 30px;
    background-color:cornflowerblue;
    border-radius: 0.7em;
    font-weight: bolder;
    color: black;
    margin: 1em 5em;
    outline: 3px solid orange;
}

.model-select {
    display: flex;
    flex-direction: column;
}

.model-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-selected {
    padding: 10px 30px;
    background-color:cornflowerblue;
    border-radius: 0.7em;
    font-weight: bolder;
    color: black;
    margin: 1em 5em;
}

.model-choose {
    padding: 10px 30px;
    background-color: orange;
    border-radius: 0.7em;
    font-weight: bolder;
    color: black;
    margin: 1em 5em;
}
</style>
  