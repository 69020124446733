<template>
    <div class="bg-gray-100 flex items-center justify-center h-screen">
        <div class="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full">
            
            <p class="text-sm text-gray-600 mb-6">Update password for enhanced account security.</p>
            <form id="changePasswordForm" class="space-y-6">
                <div>
                    <label for="new_password" class="text-sm font-medium text-gray-700 block mb-2">New Password *</label>
                    <input type="password" id="new_password" class="password-input form-input block w-full border border-gray-300 rounded-md shadow-sm" required>
                </div>
                <div>
                    <label for="confirmPassword" class="text-sm font-medium text-gray-700 block mb-2">Confirm New Password *</label>
                    <input type="password" id="confirmPassword" class="password-input form-input block border w-full border-gray-300 rounded-md shadow-sm" required>
                    <button type="button" @click="clearConfirmPassword()" class="text-xs text-blue-600 hover:underline mt-1">Clear</button>
                </div>
                <div class="flex justify-between">
                    <button @click="recoverPassword" type="submit" class="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300">Change Password</button>
                </div>
            </form>
        </div>
    </div>
</template>
  
<script>
import { changePassword } from '../services/auth-service.js';

export default {
    name: "Recoverpassword",
    mounted() {
        if (this.$route.query.token) {
            this.recover_password_token = this.$route.query.token;
        }
    },
    data() {
        return {
            recover_password_token: null,
        };
    },
    methods: {
        async recoverPassword(event) {
            const new_password = document.getElementById("new_password").value;
            event.preventDefault();

            await changePassword(this.recover_password_token, new_password)
                .then((res) => {
                    //console.log("New Password: ", res.data);
                    localStorage.setItem("login_status", "password_reset");
                    window.location.href = "/"
                })
                .catch((error) => {
                    //console.log("Changing Password ", error);
                });
            window.location.reload();
            window.location.href = "/"
        },
        clearConfirmPassword() {
            const form = document.getElementById('changePasswordForm');
            form.reset();
        },
    }
};
</script>
  

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

:root {
    --font-family: 'Inter', sans-serif;
    --font-size: 1rem;
    --bg: white;
}

html,
body {
    font-family: var(--font-family);
}

body {
    background-color: var(--bg);
}

.modal.fade .modal-content {
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
}

.modal.show .modal-content {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.modal-content {
    transform: scale(1.1);
    transition: visibility 0.25s ease-out, opacity 0.25s ease-out, transform 0.25s ease-out;
}

/** Modal static */

.modal.modal-static .modal-content {
    transform: scale(1.02);
}
</style>