import axios from 'axios';

// main request funtion provides the default values for all request.
export default axios.create({
    baseURL: "https://x2025mirovision.francecentral.cloudapp.azure.com",
    headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
    },
    withCredentials: true
});