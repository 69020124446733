<!-- Nav Bar Landing Page -->

<template>
    <div class="body">
        <div class="header-placeholder"></div>
        <nav class="header" id="header">
            <router-link class="logo" to="/">
                <img class="logo-img" src="../public/M-blue-transparent.png" alt="">
                <h1 class="title">mirovision</h1>
            </router-link>
            <div class="hamburger">
                <div ref="buttonDiv" class="menu-icon" @click="toggleMenu">&#9776;</div>
                <div ref="specificDiv" class="menu-mobile" :class="{ active: isOpen }">
                    <router-link class="menu-item" to="/Platform">{{ $t('landing.platform') }}</router-link>
                    <router-link class="menu-item" to="/models">{{ $t('landing.models') }}</router-link>
                    <router-link class="menu-item" to="/aboutUs">{{ $t('landing.about') }}</router-link>
                    <div v-if="!isLogged" class="logged-menu">
                        <router-link class="menu-item" to="/login">Login</router-link>
                    </div>
                    <div v-if="isLogged" class="logged-menu">
                        <router-link class="menu-item" to="/myprofile">My Profile</router-link>
                        <router-link class="menu-item" to="/">Help</router-link>
                        <div class="menu-item" @click="logout">Sign Out</div>
                    </div>
                    <div class="switcher-container">
                        <Switcher class=""></Switcher>
                    </div>
                </div>
            </div>
            <div class="items"></div>
            <div class="left">
                <button @click="toggleNavbar" class="menu">Menu</button>

                <!-- Select Languaje -->
                <Switcher class=""></Switcher>

                <div class="sign">
                    <div><router-link class="navbar-item" to="/Platform">{{ $t('landing.platform') }}</router-link></div>
                    <div><router-link class="navbar-item" to="/models">{{ $t('landing.models') }}</router-link></div>
                    <div><router-link class="navbar-item" to="/aboutUs">{{ $t('landing.about') }}</router-link></div>

                    <div v-if="!isLogged">
                        <router-link class="navbar-item" to="/login">Login</router-link>
                    </div>
                    <div v-if="isLogged" class="profile">
                        <Profile></Profile>
                    </div>

                    <!-- <div><router-link class="navbar-item" to="/recoverpassword">TEST</router-link></div> -->

                    <Button class="contact" @click="scrollContact" v-bind:label="$t('landing.contact')"></Button>
                </div>
            </div>
        </nav>
        <div v-if="showMenu" class="phone-nav">
            <Switcher class="nav-switch"></Switcher>
            <div><router-link class="navbar-item" to="/try">{{ $t('header.try') }}</router-link></div>
            <div><router-link class="navbar-item" to="/cameras">{{ $t('header.cameras') }}</router-link></div>
            <div><router-link class="navbar-item" to="/models">{{ $t('header.models') }}</router-link></div>

            <div v-if="!isLogged"><router-link class="navbar-item" to="/login">{{ $t('header.signin') }}</router-link>
            </div>
            <div v-if="!isLogged"><router-link class="navbar-item" to="/signup">{{ $t('header.signup') }}</router-link>
            </div>
            <div v-if="isLogged" v-tooltip.bottom="{ value: truncate(userName), class: 'custom-error' }"><router-link
                    class="user" to="/user"><img class="user-img" src="./assets/svg/User.svg" /></router-link></div>
            <div v-if="isLogged" @click="logout" v-tooltip.bottom="{ value: 'Logout', class: 'custom-error' }">
                <router-link to="/"><img class="logout-img" src="./assets/svg/Off.svg" /></router-link>
            </div>
            <Button class="contact" @click="scrollContact" v-bind:label="$t('header.contact')"></Button>
        </div>
        <router-view />
    </div>

</template>

<style scoped>
.switcher-container {
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-icon, .menu-mobile {
    display: none;
}

.profile {
    color: #272727;
}

.logo-img {
    width: 65px;
    height: 65px;
}

.title {
    font-size: 48px;
    font-weight: 700;
    display: flex;
    align-items: center;
    color: #0049b0;
}

.menu {
    display: none;
}

* {
    font-family: 'Work Sans', sans-serif;
}

.custom-error .p-tooltip-text {
    background-color: #66FCF1 !important;
    color: black !important;
    font-size: 20px !important;
    font-weight: bold !important;
    margin-top: 20px !important;
}

.header-placeholder {
    height: 80px;
}

.logout-img {
    filter: invert();
    height: 50px;
    cursor: pointer;
}

.left {
    display: flex;
}

html {
    height: 100%;
}

body {
    background-color: #ffff;
    height: 100%;
}

.body {
    height: 100%;
}

#app {
    height: 100%;
}

.header {
    top: 0;
    width: 100%;
    position: fixed !important;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    transition: top .3s;
    z-index: 999;
}

.logo {
    display: flex;
    height: 75%;
    padding-left: 70px;
}

.logo> :last-child {
    align-self: center;
    height: 50%;
    padding-left: 20px;
}

.sign {
    display: flex;
    padding-right: 20px;
    min-width: 500px;
    padding-left: 40px;
    justify-content: space-between;
    align-items: center;
}

.sign>div>* {
    color: #0049b0 !important;
    font-weight: bold;
    cursor: pointer;
}

.sign>div:hover>* {
    color: black !important;
    transition: ease-in-out .5s;
}

.sign>Button {
    background-color: #0049b0;
    font-weight: bold;
    color: white;
    margin-left: 50px;
}

.sign>Button:hover {
    background-color: black !important;
}

.user {
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-img {
    max-height: 40px;
    filter: invert();
}

.navbar-item {
    padding: 0 5px;
}

.logged-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1025px) {
    .title {
        display: none;
    }
}

@media (max-width: 775px) {

    .menu-mobile {
        position: absolute;
        left: -100vw;
        top: 5em;
        transition: left 0.75s ease;
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .menu-icon {
        display: block;
        margin-left: 5em;
    }

    .menu-mobile.active {
        display: block;
        position: absolute;
        top: 5em;
        left: 0;
        display: flex;
        flex-direction: column;
    }

    .menu-item {
        padding: 10px;
        border-bottom: 1px solid #ccc;
        background-color: white;
        text-align: center
    }

    .sign {
        display: none;
    }

    .switch {
        display: none !important;
    }

    .menu {
        display: block;
        color: black;
        margin-right: 30px;
        font-weight: bold;
    }

    .logo {
        width: 50%;
    }

    .phone-nav {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: space-between;
        z-index: 99;
        position: fixed;
        background-color: #272727;
        color: white;
        width: 50%;
        height: 50%;
    }

    .nav-switch {
        display: block !important;
        position: relative;
    }

    .title {
        display: none;
    }

    .header {
        width: 100vw;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    .left {
        display: none;
    }

    .items {
        display: none;
    }
}
</style>

<script lang="js">
import { getUserInfo } from './userService.js'
import Switcher from './components/Switcher'
import Profile from './views/Profile.vue'
import Footer from './components/LandingPage/components/Footer.vue';

export default {
    name: "area-navbar",
    created() {
        let userName = localStorage.getItem('Name')
        window.addEventListener('scroll', this.hideNavbar);
        if (localStorage.getItem('access_token')) {
            this.isLogged = true;
            // getUserInfo().then((res) => {
            //     this.userName = res.data.Name;
            // });
        }
    },
    data() {
        return {
            showMenu: false,
            scrollPos: window.pageYOffset,
            isLogged: false,
            userName: "",
            isOpen: false,
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        handleClickOutside(event) {
          if (!this.$refs.specificDiv.contains(event.target) && !this.$refs.buttonDiv.contains(event.target)) {
            this.isOpen = false;
          }
          if (this.$refs.buttonDiv.contains(event.target)) {
            this.isOpen = !this.isOpen;
          }
        },
        logout() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user_gmail');

            window.location.reload();
            window.location.href = "/"
        },
        toggleMenu() {
            //this.isOpen = !this.isOpen;
        },
        toggleNavbar() {
            this.showMenu = !this.showMenu;
        },
        hideNavbar(ev) {
            var currentScrollPos = window.pageYOffset;
            if (this.scrollPos > currentScrollPos) {
                document.getElementById("header").style.top = "0";
            } else {
                document.getElementById("header").style.top = "-80px";
            }
            this.scrollPos = currentScrollPos;
        },
        logout() {
            //console.log("User LogOut")
            localStorage.removeItem('access_token');
            //console.log("Remove Token " + localStorage.getItem("access_token"))
            window.location.reload();
        },
        truncate(data) {
            if (data.length > 15)
                data = data.substring(0, 15).concat('...');
            return (data);
        },
        scrollContact() {
            // const pos = document.getElementById('Contact-page').offsetTop;
            // //console.log(pos);
            // window.scrollTo({top: pos, behavior: "smooth"});
            this.$router.push({ name: 'ContactUs' });
        }
    },
    components: {
        Switcher,
        Profile,
        Footer
    }
}
</script>