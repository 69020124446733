<template>
    <div class="project">
        <div v-if="edit == true">
            <p class="title">Action Name*</p>
            <input class='input' type="text" v-model="projectName">
            <p class="title">{{ $t('platform.desc') }}</p>
            <input class='input' type="text" v-model="projectDescription">
            <p class="title">{{ $t('platform.models') }}</p>
            <div class="models">
                <input type="text" :placeholder="$t('platform.search')" class="input" v-model="search"
                    @input="filterModels">
                <div class="card-container">
                    <div v-for="(model, index) in selectedModels" @click="selectModel(index)"
                        :class="['model-card', model.selected ? 'selected' : '']" @mouseover="showDescription(index)"
                        @mouseout="hideDescription(index)">
                        <h3 class="model-name">{{ model.name }}</h3>
                        <p class="model-desc" v-if="hoveredIndex === index">{{ model.desc }}</p>
                    </div>
                </div>
            </div>
            <p class="title">Object from model*</p>
                <multiselect v-model="object" tag-placeholder="Add this as new tag" placeholder="Search for objects in the selected model" label="name"
                 track-by="name" :options="objectArray" :multiple="true" :taggable="true" :close-on-select="false" open-direction="top" @tag="addTag"></multiselect>
            <p class="title">Trigger*</p>
            <div class="models">
                <input type="text" placeholder="Trigger" class="input" v-model="trigger" @input="filterTriggers"
                    @focus="handleFocus('trigger')" @blur="handleBlur">
                <div v-if="focus == 'trigger'" class="card-container">
                    <div v-for="(model, index) in selectedTriggers" @click="selectTrigger(index)"
                        :class="['model-card']" @mouseover="showDescription(index)"
                        @mouseout="hideDescription(index)">
                        <h3 class="model-name">{{ model.name }}</h3>
                        <p class="model-desc" v-if="hoveredIndex === index">{{ model.desc }}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <input class='input' type="text" placeholder="0" v-model="times">
                <p v-if="selected == 'is there'" class="title">Seconds*</p>
                <p v-if="selected != 'is there'" class="title">Times*</p>
            </div>

            <p class="title">Reaction*</p>
            <div class="models">
                <input type="text" placeholder="Reaction" class="input" v-model="reaction" @input="filterReactions"
                    @focus="handleFocus('reaction')" @blur="handleBlur">
                <div v-if="focus == 'reaction'" class="card-container">
                    <div v-for="(model, index) in selectedReactions" @click="selectReaction(index)"
                        :class="['model-card']" @mouseover="showDescription(index)"
                        @mouseout="hideDescription(index)">
                        <h3 class="model-name">{{ model.name }}</h3>
                        <p class="model-desc" v-if="hoveredIndex === index">{{ model.desc }}</p>
                    </div>
                </div>
            </div>
            <div class="models">
                <p class="title">Destination*</p>
                <input class='input' type="text"  placeholder="email, phone number, url..." v-model="destination">
                <p class="title">Message*</p>
                <input class='input' type="text" v-model="message">
            </div>

            <button @click="createProject(projectName, projectDescription, search, object, trigger, times, reaction, destination, message)" class="button_1">{{ $t('platform.done') }}</button>
            <button @click="deleteProject" class="button_2">{{ $t('platform.delete') }}</button>
        </div>
        <div v-if="edit != true">
            <h1 class="name">{{ projectName }}</h1>
            <p class="description">{{ projectDescription }}</p>
            <div class="action-finished">
                when <p v-if="object.length == 1">{{ object[0].name }}</p> <p class="repeating" v-else v-for="(item, index) in object">{{item.name}} {{ index == object.length - 1 ? '' : ', '}}</p> <p>{{ trigger }}</p> <p>{{ times }}</p> {{ trigger == 'is there' ? 'seconds' : 'times' }} <p>{{ reaction }}</p> to <p>{{ destination }}</p> with <p>'{{ message }}'</p>
            </div>
            <button @click="editCard" class="button_1">{{ $t('platform.edit') }}</button>
            <button @click="runProject" class="button_1">{{ $t('platform.try_me') }}</button>
        </div>
    </div>
</template>
  
<style scoped>

.repeating {
    margin-left: 5px !important;
}

.multiselect >>> .multiselect__option--highlight, .multiselect >>> .multiselect__tag {
    background: #1D4ED8 !important;
}
.multiselect >>> .multiselect__tag-icon::after {
    color: white;
}

.multiselect >>> .multiselect__tags {
    border: 1px solid black;
    padding: 5px;
    margin-bottom: 1em;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    background-color: #ffffff;
    font-size: 100%;
}

.multiselect >>> .multiselect__placeholder,  .multiselect >>> .multiselect__input{
    margin-bottom: 0;
}
.action-finished {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 0.25rem;
    padding: 10px;
}
.action-finished > p {
    color: orange;
    margin: 0 10px;
}
.row {
    display: flex;
    align-items: center;
}
.row > input {
    width: 15%;
    margin-right: 20px;
}
.button_1 {
    margin-top: 1em;
    display: inline-flex;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-right: 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background-color: #1D4ED8;
}

.button_2 {
    padding-top: 0.75rem;
    border-radius: 0.5rem;
    padding-bottom: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 600;
    color: #ffffff;
    background-color: #F87171;
    background-color: #EF4444;
}

.models .input {
    margin-bottom: 20px;
    /* Space between input and cards */
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.model-card {
    border: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    border-radius: 0.5rem;
    background-color: #ffffff;
    cursor: pointer;
}

.model-card > h3 {
    font-size: 14px;
}

.selected {
    background-color: #e9e9e9;
    /* Highlight color for selected card */
}

.model-name {
    margin-top: 0;
    font-size: 18px;
    /* Adjust font size as needed */
}

.model-desc {
    font-size: 14px;
    /* Adjust font size as needed */
    color: #666;
    margin-top: 10px;
}


.name {
    color: #393E46;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
}

.description {
    color: #393E46;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
}

.post {
    display: flex;
    background-color: #393E46;
    align-items: center;
    justify-content: space-evenly;
    height: 70px;
}

.post>h1 {
    background-color: #89E07B;
    color: white;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 133.333% */
    letter-spacing: 3px;
    height: fit-content;
    padding: 5px 10px;
    border-radius: 0.2em;
}

.post>p {
    color: #EEE;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 70%;
}

.true {
    background-color: #0049b0;
    color: white;
}

.select-models {
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    overflow-y: scroll;
    height: 100px;
}

.models {
    display: flex;
    flex-direction: column;
}

.title {
    color: #393E46;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.input {
    border: 1px solid black;
    padding: 5px;
    margin-bottom: 1em;

    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    color: #111827;
    background-color: #ffffff;
}

.inactive {
    border-radius: 0.2em;
    background: rgba(223, 223, 223, 0.93);
    backdrop-filter: blur(2px);
    padding: 5px 20px;
    margin: 10px;
    transition: all 0.5s;
}

.active {
    border-radius: 0.2em;
    background: #0049b0;
    color: white;
    backdrop-filter: blur(2px);
    padding: 5px 20px;
    margin: 10px;
    transition: all 0.5s;
}

.project {
    width: 455px;
    height: fit-content;
    border-radius: 0.5rem;
    background: #eef7ff;
    padding: 30px;
    margin: 10px;
}

.description-box {
    position: absolute;
    top: inherit;
    left: inherit;
    margin-left: 17%;
    margin-top: -2%;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 5px;
    color: black;
}

@media (max-width: 760px) {
    .project {
        width: 90%;
        margin: auto;
    }

    .description-box {
        display: none;
    }
}
</style>
  
<script lang="js">
import { defineComponent } from 'vue';
import { createProj, deleteProj, getModels, createAction } from '../../userService.js';
import Multiselect from 'vue-multiselect'
import { showToast } from '../../utils/utils.js';

export default defineComponent({
    name: 'Project',
    data() {
        return {
            cameraId: '',
            image: 'active',
            video: 'inactive',
            times: 0,
            reaction: '',
            search: '',
            object: [],
            trigger: '',
            selected: '',
            selectedId: 0,
            projectName: '',
            projectDescription: '',
            destination: '',
            message: '',
            models: [
            ],
            selectedModels: [
            ],
            triggers: [
                { name: 'appears', desc: 'when object form model appears on the screen', selected: false, id: 0 },
                { name: 'disappears', desc: 'when object form model disappears from the screen after being there', selected: false, id: 1 },
                { name: 'is there', desc: 'when object form model appears and stays there for a defined amount of time', selected: false, id: 2 },
                { name: 'missing', desc: 'when object form model does not appear on the screen', selected: false, id: 3 },
            ],
            selectedTriggers: [
                { name: 'appears', desc: 'when object form model appears on the screen', selected: false, id: 0 },
                { name: 'disappears', desc: 'when object form model disappears from the screen after being there', selected: false, id: 1 },
                { name: 'is there', desc: 'when object form model appears and stays there for a defined amount of time', selected: false, id: 2 },
                { name: 'missing', desc: 'when object form model does not appear on the screen', selected: false, id: 3 },
            ],
            reactions: [
                { name: 'send email', desc: 'sends email to specified email address', selected: false, id: 0 },
                { name: 'send sms', desc: 'sends sms to specified phone number', selected: false, id: 1 },
                { name: 'send post request', desc: 'sends post request to specified url', selected: false, id: 2 },
            ],
            selectedReactions: [
                { name: 'send email', desc: 'sends email to specified email address', selected: false, id: 0 },
                { name: 'send sms', desc: 'sends sms to specified phone number', selected: false, id: 1 },
                { name: 'send post request', desc: 'sends post request to specified url', selected: false, id: 2 },
            ],
            objectArray: [
                {name: 'person'},
                {name: 'cat'},
                {name: 'dog'},
                {name: 'car'},
                {name: 'building'},
                {name: 'tree'},
                {name: 'stop sign'},
                {name: 'traffic light'},
                {name: 'bus'},
            ],
            hoveredIndex: null,
            focus: false,
        };
    },
    props: {
        name: String,
        desc: String,
        msg: String,
        obj: Array,
        react: String,
        tms: Number,
        dest: String,
        trgr: String,
        uuid: String,
        edit: Boolean,
    },
    mounted() {
        this.projectName = this.name;
        this.projectDescription = this.desc;
        this.message = this.msg;
        this.object = this.object.concat(this.obj).map(name => ({name}));
        this.reaction = this.react;
        this.times = this.tms;
        this.destination = this.dest;
        this.trigger = this.trgr;
        this.fetchModels()
        //console.log("on project.vue: " + this.uuid);
    },
    components: {
        Multiselect,
        showToast
    },
    methods: {
        addTag(newTag) {
            const tag = {
                name: newTag,
            }
            this.objectArray.push(tag)
            this.object.push(tag)
        },
        handleFocus(input) {
            this.focus = input;
        },
        handleBlur() {
            setTimeout(() => {
                this.focus = false;
            }, 100);
        },
        showDescription(index) {
            this.hoveredIndex = index;
        },
        hideDescription(index) {
            this.hoveredIndex = null;
        },
        runProject() {
            this.$emit('run', this.uuid);
        },
        async fetchModels() {
            try {
                const res = await getModels();
                for (const model of res.data) {
                    this.models.push({
                        name: model.name,
                        desc: model.description,
                        selected: false,
                        id: model.uuid,
                    });
                    this.selectedModels.push({
                        name: model.name,
                        desc: model.description,
                        selected: false,
                        id: model.uuid,
                    });
                }
                //console.log(this.model);
                for (var i = 0; i < this.selectedModels.length; i++) {
                    if (this.selectedModels[i].id == this.model) {
                        this.selectedModels[i].selected = 'true'
                        this.selected = this.selectedModels[i].name;
                        this.selectedId = this.selectedModels[i].id;
                    }
                }
            } catch (error) {
                this.incorrect = true;
                this.alert = error;
            }
        },
        createProject: function () {
            createAction(this.projectName, this.projectDescription, this.object[0]["name"], this.trigger, this.times, this.reaction, this.destination, this.message).then((res) => {
              showToast("success", "Action successfully created");
            })
            .catch((error) => {
                    //console.log(error)
            });
            //this.$emit('edit', false);
            //const actionData = {
            //    name: this.projectName,
            //    description: this.projectDescription,
            //    model: this.search,
            //    objects: this.object,
            //    trigger: this.trigger,
            //    times: this.times,
            //    reaction: this.reaction,
            //    dest: this.destination,
            //    message: this.message
            //}
            //var actions = JSON.parse(sessionStorage.getItem('actions'));
            //if (actions == null) {
            //    actions = []
            //}
            //actions.push(actionData)
            //sessionStorage.setItem('actions', JSON.stringify(actions));
            this.$emit('edit', false);
        },
        deleteProject: function () {
            this.$emit('delete', this.uuid);
            showToast("error", "Action successfully created");

        },
        changeType: function () {
            if (this.image == 'active') {
                this.image = 'inactive';
                this.video = 'active';
            } else {
                this.image = 'active';
                this.video = 'inactive';
            }
        },
        selectModel: function (index) {
            for (var i = 0; i < this.selectedModels.length; i++) {
                this.selectedModels[i].selected = false;
            }
            this.selectedModels[index].selected = true;
            this.selected = this.selectedModels[index].name;
            this.selectedId = this.selectedModels[index].id;
            this.search = this.selected;
        },
        filterModels: function () {
            this.selectedModels = [];
            for (var i = 0; i < this.models.length; i++) {
                if (this.models[i].name.toLowerCase().includes(this.search.toLowerCase())) {
                    this.selectedModels.push(this.models[i]);
                }
            }
        },
        selectTrigger: function (index) {
            for (var i = 0; i < this.selectedTriggers.length; i++) {
                this.selectedTriggers[i].selected = 'false';
            }
            this.selectedTriggers[index].selected = 'true';
            this.selected = this.selectedTriggers[index].name;
            this.selectedId = this.selectedTriggers[index].id;
            this.trigger = this.selected;
        },
        selectReaction: function (index) {
            for (var i = 0; i < this.selectedReactions.length; i++) {
                this.selectedReactions[i].selected = 'false';
            }
            this.selectedReactions[index].selected = 'true';
            this.selected = this.selectedReactions[index].name;
            this.selectedId = this.selectedReactions[index].id;
            this.reaction = this.selected;
        },
        filterTriggers: function () {
            this.selectedTriggers = [];
            for (var i = 0; i < this.triggers.length; i++) {
                if (this.triggers[i].name.toLowerCase().includes(this.trigger.toLowerCase())) {
                    this.selectedTriggers.push(this.triggers[i]);
                }
            }
        },
        filterReactions: function () {
            this.selectedReactions = [];
            for (var i = 0; i < this.reactions.length; i++) {
                if (this.reactions[i].name.toLowerCase().includes(this.reaction.toLowerCase())) {
                    this.selectedReactions.push(this.reactions[i]);
                }
            }
        },
        done: function () {
            this.$emit('edit', false);
            this.$emit('name', this.projectName);
            this.$emit('desc', this.projectDescription);
            this.$emit('type', this.image == 'active' ? 0 : 1);
            this.$emit('model', this.selected);
        },
        editCard: function () {
            this.$emit('edit', true);
        }
    }
})
</script>