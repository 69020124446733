<template>
    <div class="platform">
        <div class="page-title">{{ $t('platform.gallery') }}</div>
        <div class="page-second">{{ $t('platform.create') }}</div>
        <div class="tabs">
            <button @click="projectsTab" :class="['tab', { selected: selected === 0 }]">Projects</button>
            <button @click="actionsTab" :class="['tab', { selected: selected === 1 }]">Actions</button>
        </div>
        <div class="empty" v-if="projects.length == 0 && this.selected == 0">
            <h1 class="project-info">{{ $t('model.projects') }}</h1>
            <button @click="createProject" class="button_1">{{ $t('model.create') }}</button>
        </div>
        <div class="projects">
            <div v-if="selected == 0 && projects.length > 0" class="container">
                <div v-for="(project, index) in projects">
                    <Project :name="project.name" :desc="project.desc" :type="project.type" :model="project.model"
                        :edit="project.edit" :uuid="project.uuid" :actions="project.actions" v-on:edit="changeEdit(index)"
                        v-on:run="displayUUID(index, $event)" v-on:delete="deleteProject(index, $event)">
                    </Project>
                </div>
            </div>
            <div v-if="selected == 1 && actions.length > 0" class="container">
                <div v-for="(action, index) in actions">
                    <Action :name="action.name" :desc="action.desc" :msg="action.msg" :obj="action.obj" :react="action.react" :tms="action.tms" :dest="action.dest" :trgr="action.trgr" :uuid="action.uuid" :edit="action.edit" v-on:edit="changeEditAction(index)"
                        v-on:run="displayUUID(index, $event)" v-on:delete="deleteProject(index, $event)">
                    </Action>
                </div>
            </div>
            <button v-if="projects.length > 0 && selected == 0" @click="createProject" class="button_1">{{
                $t('platform.create_caps') }}</button>
            <button v-if="selected == 1" @click="createAct" class="button_1">NEW ACTION</button>
        </div>
        <p class="writing" v-if="selected === 0">Run project and display results:</p>
        <!-- Display Results Section - Only visible when Projects tab is selected -->
        <div class="projects-display-box" v-if="selected === 0">
            <!-- New Section Title -->


            <!-- Choose File Button -->
            <div class="row">
                <div class="image-input_new">
                    <label for="file-upload" class="custom-file-upload_new">{{ $t('platform.choose_file') }}</label>
                    <input id="file-upload" type="file" @change="handleFileChange" accept="image/*" />
                </div>
                <!-- Display message when a file is selected -->
                <p v-if="selectedFile" class="file-selected-message highlighted-text">Image has been selected | Ready to
                    run project</p>
                <button @click="downloadResult" v-if="projectRun" class="download">Download Image</button>
            </div>

            <div class="project_results">
                <div class="canvas-container_new">
                    <canvas v-if="projectRun" ref="canvas"></canvas>
                </div>
                <div v-if="projectRun" class="code-container">
                    <pre>
                      <code ref="codeBlock">
                        {{ this.results }}
                      </code>
                    </pre>

                    <button @click="copyCode" class="copy-button">
                        {{ copied ? 'Copied!' : 'Copy' }}
                    </button>
                </div>
            </div>
        </div>

        <p class="writing" v-if="selected === 0">{{ $t('platform.integrate') }}</p>

        <div class="projects-display-box" v-if="selected === 0">
            <!-- Integration Section -->
            <p class="card-description_new">{{ $t('platform.api') }}</p>
            <p class="card-description_new">{{ $t('platform.results') }}</p>
            <pre class="code-example_new">{{ result_model }}</pre>

            <!-- Example Section -->
            <h3 class="card-title_new">{{ $t('platform.example') }}</h3>
            <pre class="code-example_new">
                drawRectangles(result_from_model) {
                    const canvas = this.$refs.canvas;
                    const ctx = canvas.getContext('2d');
                    const img = new Image();
                    img.onload = () => {
                        const maxWidth = window.innerWidth * 0.5;
                        canvas.width = Math.min(img.width, maxWidth);
                        const aspectRatio = img.height / img.width;
                        canvas.height = canvas.width * aspectRatio;
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                        const scaleX = canvas.width / img.width;
                        const scaleY = canvas.height / img.height;
                        result_from_model.forEach(detection => {
                            const color = this.getRandomColor();
                            const { xmin, ymin, xmax, ymax, label } = detection.box;
                            ctx.beginPath();
                            ctx.rect(xmin * scaleX, ymin * scaleY, (xmax - xmin) * scaleX, (ymax - ymin) * scaleY);
                            ctx.lineWidth = 3;
                            ctx.strokeStyle = color;
                            ctx.stroke();
                            ctx.fillStyle = color;
                            ctx.font = '16px Arial';
                            ctx.fillText(label, xmin * scaleX, ymin * scaleY - 5);
                        });
                    };
                    img.src = this.selectedImage;
                }
            </pre>
        </div>
    </div>
</template>

<style scoped>
.code-container {
    position: relative;
    height: 100%;
    margin-left: 20px;
}

pre {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    overflow-x: scroll;
    overflow-y: scroll;
    max-height: 500px;
}

/* Hide scrollbars */
pre::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari and Opera */
}

pre {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

code {
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    white-space: pre-wrap;
    /* Ensures line breaks in long lines */
}

.copy-button {
    margin-top: 10px;
    padding: 5px 15px;
    background-color: #42b983;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.copy-button:hover {
    background-color: #36a074;
}

.project_results {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    justify-content: center;
    height: fit-content;
    margin-top: 40px;
}

.download {
    border: 1px solid #ced4da;
    background-color: #f8f9fa;
    cursor: pointer;
    padding: 6px 12px;
    margin-left: 5px;
    min-width: fit-content;
    border-radius: 0.2em;
}

.projects-display-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Align content inside the box to the left */
    justify-content: center;
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    /* Center the box horizontally */
    border: 2px solid #ccc;
    /* Add a visible border */
    border-radius: 8px;
    /* Add rounded corners */
    background-color: #f9f9f9;
    /* Set a light background color */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* Add a subtle shadow for depth */
    margin-top: 20px;
    /* Add space at the top */
    margin-bottom: 20px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-left: 0;
    /* Align to the left */
}

.row>p {
    margin-top: 0;
}

.tabs {
    display: flex;
    width: 100%;
    margin-top: 1%;
    align-items: center;
    justify-content: center;
}

.button_1 {
    display: inline-flex;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-right: 0.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background-color: #1D4ED8;
}

.file-selected-message {
    margin-top: 10px;
    font-weight: bold;
    color: #155724;
}

.highlighted-text {
    padding: 5px 10px;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
}

.canvas-container_new {
    text-align: center;
    /* Align the canvas container to the left */
    width: 50%;
}

.custom-file-upload_new {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 0.2em;
    margin-right: 5px;
    min-width: fit-content;
}

.custom-file-upload_new:hover {
    background-color: #e2e6ea;
}

.button_2 {
    display: inline-flex;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background-color: #1D4ED8;
    min-width: fit-content;
}

.integration-section_new {
    margin-top: 20px;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 0.2em;
}

.code-example_new {
    background-color: #eef;
    padding: 10px;
    font-family: monospace;
    overflow-x: auto;
}

.instructions-list_new {
    list-style: inside decimal;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #555;
}

.instructions-list_new li {
    margin-bottom: 8px;
    font-size: 16px;
}

.model-card_new {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0.2em;
    padding: 20px;
    margin: 20px;
    width: auto;
}

.card-title_new {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.card-description_new {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.image-input_new {
    min-width: fit-content;
}

.multiselect>>>.multiselect__option--highlight,
.multiselect>>>.multiselect__option--highlight::after {
    background: #0049b0;
}

.custom-file-upload_new {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 0.2em;
    margin-right: 5px;
    min-width: fit-content;
}

.custom-file-upload_new:hover {
    background-color: #e2e6ea;
}

.writing_new {
    color: #333;
    font-size: 16px;
    margin-bottom: 10px;
}


.page-title,
.page-description {
    text-align: center;
    margin: 20px;
}

.page-second {
    text-align: center;
}

.page-title {
    font-size: 2em;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 4px 9px;
    cursor: pointer;
    color: white;
    background-color: #0049b0;
    margin-left: 10px;
    border-radius: 0.2em;
}

.image-input {
    display: flex;
    align-items: center;
}

.proj-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: 100px;
    margin-top: 50px;
}

.cam {
    margin-top: 20px;
}

.tab {
    font-size: 16px;
    font-weight: bold;
    background-color: gray;
    color: white;
    margin-right: 10px;
    border-radius: 0.5rem;
    padding: 0.5rem 1.5rem;
    transition: all 0.5s;
}

.selected {
    background-color: #1D4ED8;
}

.writing {
    text-align: center;
    margin-top: 40px;
    font-size: 20px;
    font-weight: bold;
}

.input_class {
    padding-left: 20px;
}

.canvas-container {
    display: flex;
    /* Enable flexbox */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    height: 100%;
    /* Take full height of the container (or as needed) */
}

canvas {
    border: 1px solid #000;
    max-width: 100%;
    /* Maximum width is 50% of the screen */
    height: auto;
    /* Maintain aspect ratio */
}

.snapshot {
    background-color: #0049b0;
    color: white;
    padding: 10px 40px;
}

video {
    border: 1px solid black;
    width: 500px !important;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.container {
    display: flex;
    flex-wrap: wrap;
    /* Allow items to wrap */
    justify-content: flex-start;
    /* Align items to the start */
    gap: 20px;
    /* Provide some space between the projects */
    padding-bottom: 20px;
}

.project-item {
    flex: 1;
    /* Allow each project item to grow */
    min-width: 300px;
    /* Minimum width for each project item */
}

.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* Align to the start to accommodate the Create button */
    padding-top: 50px;
    width: 100%;

    gap: 20px;
    /* Space between project list and create button */
}

/* Adjust the .platform height to be auto to accommodate the expanded content */
.platform {

    width: 100%;
    height: auto;
    /* Changed from 100vh to auto */
    padding-bottom: 50px;
    /* Add some padding at the bottom */
}

.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    flex-direction: column;
    margin-top: 100px;
}

.empty>h1 {
    font-size: 30px;
    color: #0049b0;
    font-weight: 600;
}

.create {
    display: block;
    margin-top: 20px;
    background-color: #0049b0;
    padding: 10px 40px;
    color: white;
    transition: all 0.5s;
    border-radius: 0.2em;
}

.create:hover {
    background-color: black;
}

.admin {
    display: inline-block;
    margin-top: 20px;
    background-color: #0049b0;
    border-radius: 0.2em;
    padding: 10px 40px;
    color: white;
    transition: all 0.5s;
}

.admin:hover {
    background-color: black;
}

.snapshot-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 760px) {
    .projects {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
    }

    .project,
    .container {
        width: 100%;
    }

    .container>div {
        width: 100%;
    }

    .project-info {
        text-align: center;
    }

    .image-input {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .model-card_new {
        max-width: 100%;
    }
}
</style>

<script lang="js">
import { defineComponent, ref } from 'vue';
import Project from '../components/Platform/Project';
import Action from '../components/Platform/Action';
import Camera from "simple-vue-camera";
import { getProj, runProj, deleteProj, getActions, createAction, deleteAction } from "../userService.js";
import Multiselect from 'vue-multiselect';

import { showToast } from '../utils/utils.js';

export default defineComponent({
    name: 'Platform',
    data() {
        return {
            projects: [],
            actions: [],
            selectedUUIDs: {},
            selectedUUID: null,
            selectedFile: null,
            selectedImage: null,
            projectRun: false,
            result_model: [],
            selected: 0,
            tryModel: null,
            results: null,
            copied: false,
            tryModels: [
                { name: 'facebook/detr-resnet-50' },
                { name: 'google' },
                { name: 'coco' },
                { name: 'meta' },
            ]
        };
    },
    setup() {
        // Get a reference of the component
        const camera = ref();

        // Use camera reference to call functions
        const snapshot = async () => {
            //console.log("snap")
            const blob = await camera.value?.snapshot();

            // To show the screenshot with an image tag, create a url
            const url = URL.createObjectURL(blob);
            //console.log(url)
        }

        return {
            camera,
            snapshot
        }
    },
    components: {
        Project,
        Action,
        Camera,
        Multiselect
    },
    mounted() {
        getProj().then((res) => {
            //console.log(res)
            for (const proj of res.data) {
                console.log(proj)
                this.projects.push({ name: proj.name, desc: proj.description, type: 0, model: proj.models[0].uuid, edit: false, uuid: proj.uuid, actions: proj.actions })
            }
        })
        .catch((error) => {
            //console.log(error)
            this.incorrect = true;
            this.alert = error;
        });
        getActions().then((res) => {
            for (const action of res.data) {
                //console.log(action)
                this.actions.push({ name: action.name, desc: action.description, dest: action.destination, msg: action.msg, obj: [action.object], react: action.reaction, tms: action.times, trgr: action.trigger, uuid: action.uuid, edit: false })
            }
        })
        .catch((error) => {
            //console.log(error)
            this.incorrect = true;
            this.alert = error;
        });
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('create')) {
            let uuid = '';
            uuid = urlParams.get('uuid');
            //console.log("this is the uuid: " + uuid);
            this.createProject(uuid);
        }
    },
    methods: {
        addTag(newTag) {
            const tag = {
                name: newTag,
            }
            this.tryModels.push(tag)
            this.tryModel.push(tag)
        },
        actionsTab() {
            this.selected = 1;
        },
        projectsTab() {
            this.selected = 0;
        },
        drawRectangles(detections) {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.onload = () => {
                // Set canvas width to image width or maximum allowed width
                const maxWidth = window.innerWidth * 0.5; // 50% of viewport width
                canvas.width = Math.min(img.width, maxWidth);

                // Calculate height to maintain aspect ratio
                const aspectRatio = img.height / img.width;
                canvas.height = canvas.width * aspectRatio;

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);


                // Draw each detection rectangle (scaled accordingly)
                const scaleX = canvas.width / img.width;
                const scaleY = canvas.height / img.height;
                detections.forEach(detection => {
                    const color = this.getRandomColor();
                    const { xmin, ymin, xmax, ymax, label } = detection.box;
                    ctx.beginPath();
                    ctx.rect(xmin * scaleX, ymin * scaleY, (xmax - xmin) * scaleX, (ymax - ymin) * scaleY);
                    ctx.lineWidth = 3;
                    ctx.strokeStyle = color;
                    ctx.stroke();
                    ctx.fillStyle = color;
                    ctx.font = '16px Arial';
                    ctx.fillText(detection.label, xmin * scaleX, ymin * scaleY - 5);
                });
            };
            img.src = this.selectedImage;
        },
        getRandomColor() {
            const colors = ['red', 'green', 'blue', 'yellow', 'orange', 'purple'];
            const randomIndex = Math.floor(Math.random() * colors.length);
            return colors[randomIndex];
        },
        createProject(uuid) {
            //console.log("this is the uuid when creating a project: " + uuid);
            const newProject = { name: '', desc: '', type: 0, actions: [], model: uuid, edit: true, uuid: "" };
            this.projects.push(newProject);
        },
        createAct(uuid) {
            //console.log("this is the uuid when creating a project: " + uuid);
            const newAction = { name: '', desc: '', type: 0, model: uuid, obj: [], edit: true, uuid: "" };
            this.actions.push(newAction);
        },
        displayUUID(index, uuid) {
            this.selectedUUID = uuid;
            if (this.selectedFile) {
                this.runProject();
            } else {
                showToast("warning", "No file chosen to run project");

            }
        },
        runProject() {
            //console.log("Running project");
            this.projectRun = true;
            runProj(this.selectedUUID, this.selectedFile).then((res) => {
                //console.log(res.data[0]);
                this.result_model = res.data[0];
                this.drawRectangles(res.data[0]);
                this.results = res.data;
            })
                .catch((error) => {
                    //console.log(error)
                });
        },
        downloadResult: function () {
            const canvas = this.$refs.canvas;
            const image = canvas.toDataURL('image/png');  // Convert canvas to data URL

            // Create a link element and trigger the download
            const link = document.createElement('a');
            link.href = image;
            link.download = 'result-image.png';  // The name of the downloaded file
            link.click();
        },
        changeEdit: function (index) {
            this.projects[index].edit = !this.projects[index].edit;
        },
        changeEditAction: function (index) {
            this.actions[index].edit = !this.actions[index].edit;
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedImage = URL.createObjectURL(file);
                this.selectedFile = file;
            }
        },
        deleteProject: function (index, id) {
            if (index >= 0 && index < this.projects.length) {
                this.projects.splice(index, 1); // Remove 1 element at the specified index
                deleteProj(id).then((res) => {
                    //console.log(res);
                })
                    .catch((error) => {
                        //console.log(error);
                    });
            } else {
                //console.log("Index out of range.");
            }
        },
        newAction: function (jsonData) {
            this.actions.add(jsonData)
        },
        copyCode() {
            const codeBlock = this.$refs.codeBlock.innerText;

            // Create a temporary text area to copy the code content
            const textArea = document.createElement("textarea");
            textArea.value = codeBlock;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);

            // Show feedback that the code has been copied
            this.copied = true;

            // Reset 'Copied!' text after 2 seconds
            setTimeout(() => {
                this.copied = false;
            }, 2000);
        }
    }
})
</script>