<!-- Keep working Screen 2024 -->

<template>
     <div class="bg-gradient-to-r from-[#020024] via-[#090979] to-[#016FEA] via-[#FFFFFF] min-h-screen">

    <div class="container mx-auto py-16 flex items-center justify-center">
      <div class="bg-white shadow-md overflow-hidden rounded-lg pb-8">
        <div class="border-t border-gray-200 text-center pt-8">
          <h1 class="errorCode">404</h1>
          <h1 class="text-6xl font-medium py-8">🚧 {{ $t('errors.inprogressT') }}</h1>
          <p class="text-2xl pb-8 px-12 font-medium">{{ $t('errors.inprogressD') }}</p>

          <router-link 
            to="/" 
            class="home">
            {{ $t('landing.home') }}
          </router-link>

          <router-link 
          to="/contactUs"
            class="contact">
            {{ $t('landing.contact') }}
          </router-link>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.errorCode {
    font-size: 8rem;
line-height: 1; 
font-weight: 700; 
color: #1D4ED8; 

}

.home {
display: inline-flex; 
padding-top: 0.75rem;
padding-bottom: 0.75rem; 
padding-left: 3rem;
padding-right: 3rem; 
margin-right: 0.75rem; 
justify-content: center; 
align-items: center; 
border-radius: 0.5rem; 
font-size: 1rem;
line-height: 1.5rem; 
font-weight: 500; 
text-align: center; 
color: #ffffff; 
background-color: #1D4ED8; 
}

.home:hover {
    background-color: #1E40AF; 

}

.contact {
    display: inline-flex; 
padding-top: 0.75rem;
padding-bottom: 0.75rem; 
padding-left: 3rem;
padding-right: 3rem; 
margin-right: 0.75rem; 
justify-content: center; 
align-items: center; 
border-radius: 0.5rem; 
font-size: 1rem;
line-height: 1.5rem; 
font-weight: 500; 
text-align: center; 
color: #ffffff; 
background-color: #1D4ED8; 

    
    border-radius: 0.5rem;
    border-width: 2px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    background-color: #ffffff;
    color: #0049b0;
}

.contact:hover {
    background-color: #f7eded;
    color: rgb(0, 0, 0);
}
</style>

<script lang="js">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Pricing',
    created() {
    },
    data() {
        return {

        }
    },
    methods: {

    }

});
</script>