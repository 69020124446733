<template>

    <section class="bg-blueGray-200 -mt-24">
        
        <div class="container mx-auto px-6">
            <div class="mt-6 md:flex md:items-center md:justify-between">
                <div>
                    <h1 class="text-3xl font-semibold capitalize text-gray-800 dark:text-white lg:text-4xl">
                        RoadMap
                    </h1>
                    
                    <div class="mx-auto mt-6 flex">
                        <span class="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                        <span class="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                        <span class="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex mt-10 h-1/2 items-center justify-center bg-white px-6 md:px-60">

            <div class="space-y-6 border-l-2 border-dashed">
                <div class="relative w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-blue-500">
                        <path fill-rule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clip-rule="evenodd" />
                    </svg>
                    <div class="ml-6">
                        <h4 class="font-bold text-blue-500">{{$t('roadmap.1.title')}}</h4>
                        <p class="mt-2 max-w-screen-sm text-sm text-gray-500">
                            {{$t('roadmap.1.body')}}
                        </p>
                        <span class="mt-1 block text-sm font-semibold text-blue-500">2023</span>
                    </div>
                </div>
                <div class="relative w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-blue-500">
                        <path fill-rule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clip-rule="evenodd" />
                    </svg>
                    <div class="ml-6">
                        <h4 class="font-bold text-blue-500">{{$t('roadmap.2.title')}}</h4>
                        <p class="mt-2 max-w-screen-sm text-sm text-gray-500">
                            {{$t('roadmap.2.body')}}
                        </p>
                        <span class="mt-1 block text-sm font-semibold text-blue-500">2023</span>
                    </div>
                </div>
                <div class="relative w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-blue-500">
                        <path fill-rule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clip-rule="evenodd" />
                    </svg>
                    <div class="ml-6">
                        <h4 class="font-bold text-blue-500">{{$t('roadmap.3.title')}}</h4>
                        <p class="mt-2 max-w-screen-sm text-sm text-gray-500">
                            {{$t('roadmap.3.body')}}
                        </p>
                        <span class="mt-1 block text-sm font-semibold text-blue-500">2023</span>
                    </div>
                </div>
                <div class="relative w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-blue-500">
                        <path fill-rule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clip-rule="evenodd" />
                    </svg>
                    <div class="ml-6">
                        <h4 class="font-bold text-blue-500">{{$t('roadmap.4.title')}}</h4>
                        <p class="mt-2 max-w-screen-sm text-sm text-gray-500">{{$t('roadmap.4.body')}}</p>
                        <span class="mt-1 block text-sm font-semibold text-blue-500">2023 - 2024</span>
                    </div>
                </div>

                <div class="relative w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-blue-500">
                        <path fill-rule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clip-rule="evenodd" />
                    </svg>
                    <div class="ml-6">
                        <h4 class="font-bold text-blue-500">{{$t('roadmap.5.title')}}</h4>
                        <p class="mt-2 max-w-screen-sm text-sm text-gray-500">{{$t('roadmap.5.body')}}</p>
                        <span class="mt-1 block text-sm font-semibold text-blue-500">2024</span>
                    </div>
                </div>

                <div class="relative w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-blue-500">
                        <path fill-rule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                            clip-rule="evenodd" />
                    </svg>
                    <div class="ml-6">
                        <h4 class="font-bold text-blue-500">{{$t('roadmap.6.title')}}</h4>
                        <p class="mt-2 max-w-screen-sm text-sm text-gray-500">{{$t('roadmap.6.body')}}</p>
                        <span class="mt-1 block text-sm font-semibold text-blue-500">2024</span>
                    </div>
                </div>
            </div>
        </div>
    </section>


</template>

<style scoped></style>

<script lang="js">
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'Roadmap',
    created() {
    },
    data() {
        return {
            
        }
    },
    methods: {

    },
    components: {

    }

});
</script>