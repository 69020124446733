<template>
    <div class="tryPage">
        <SelectModelTry v-on:selectedModel="selectModel"></SelectModelTry>
        <div class="upload">
            <label class="custom-file-upload">
                <input @change="newFile" type="file" />
                <i class="fa fa-cloud-upload"></i>
                {{$t('model.select')}}
            </label>
            <p class="file-name">{{file.name}}</p>
            <button class="submit-button" @click="runModel">{{$t('model.try')}}</button>
        </div>
        <img id="server-image" alt="">
    </div>
</template>
  
<style>

</style>
  
<script lang="js">
import { defineComponent } from 'vue';
import SelectModelTry from '../components/SelectModelTry';
import { tryModel } from '../cameraServices.js'

export default defineComponent({
    name: 'TryModel',
    data() {
        return {
            file: "",
            serverImg: "",
            chosenModel: ""
        };
    },
    mounted() {
    },
    components: {
        SelectModelTry
    },
    methods: {
        selectModel(ev) {
            this.chosenModel = ev;
        },
        newFile(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.file = files[0];
        },
        runModel() {
            //console.log(this.file)
            tryModel(this.file, this.chosenModel).then((res) => {
                this.serverImg = res.data; 
                //console.log(res);
                document.getElementById('server-image').src = "data:image/jpeg;base64," + res.data;
            });
        }
    },
    mounted() {
    }
})
</script>

<style scoped>
.upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.file-name {
    color: white;
    margin-top: 5px;
}
.camera-img {
    width: 5em;
    height: 5em;
}

label {
    background-color: white;
    display: flex;
    flex-direction: column;
    color: black;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 20px 60px;
    border-radius: 1em;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.tryPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10em;
}

.submit-button {
    background-color: rgba(64, 145, 108, 1);
    color: white;
    font-weight: bolder;
    padding: 10px;
    border-radius: 0.5em;
    margin-top: 1em;
    cursor: pointer;
}
</style>