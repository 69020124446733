<template>
    <!-- MODELS -->
    <section class="bg-white dark:bg-white-900 pb-6">
        <div class="container mx-auto px-6 pt-28">
            <h1 class="text-2xl font-semibold text-gray-900 lg:text-4xl">{{$t('landing.models')}}</h1>

            <div class="mt-8 lg:-mx-12 lg:flex xl:mt-16">
                <div class="lg:mx-12">
                    <div class="mt-4 space-y-4 lg:mt-8">
                        <router-link to="/models" class="block text-gray-500 hover:underline dark:text-gray-800">{{$t('landing.detection')}}</router-link>
                        <router-link to="/models" class="block text-gray-500 hover:underline dark:text-gray-800">{{$t('landing.facial')}}</router-link>
                        <router-link to="/models" class="block text-gray-500 hover:underline dark:text-gray-800">{{$t('landing.image_class')}}</router-link>
                        <router-link to="/models" class="block text-gray-500 hover:underline dark:text-gray-800">{{$t('landing.image_seg')}}</router-link>
                    </div>
                </div>

                <div class="mt-8 flex-1 lg:mx-12 lg:mt-0">
                    <div class="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
                        <div>
                            <img class="h-96 w-full rounded-lg object-cover"
                                src="https://images.unsplash.com/photo-1621111848501-8d3634f82336?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1565&q=80"
                                alt="" />
                            <h2 class="mt-4 text-2xl font-semibold capitalize text-gray-800">
                                {{$t('landing.pattern')}}</h2>
                            <router-link to="/Platform" class="mt-2 text-lg uppercase tracking-wider text-blue-500 dark:text-blue-400">{{$t('landing.try')}}
                            </router-link>
                        </div>

                        <div>
                            <img class="h-96 w-full rounded-lg object-cover"
                                src="https://images.unsplash.com/photo-1621609764180-2ca554a9d6f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80"
                                alt="" />
                            <h2 class="mt-4 text-2xl font-semibold capitalize text-gray-800">
                                {{$t('landing.car')}}
                            </h2>
                            <router-link to="/Platform" class="mt-2 text-lg uppercase tracking-wider text-blue-500 dark:text-blue-400">{{$t('landing.try')}}
                            </router-link>
                        </div>

                        <div>
                            <img class="h-96 w-full rounded-lg object-cover"
                                src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                                alt="" />
                            <h2 class="mt-4 text-2xl font-semibold capitalize text-gray-800">
                                {{$t('landing.time')}}</h2>
                            <router-link to="/Platform" class="mt-2 text-lg uppercase tracking-wider text-blue-500 dark:text-blue-400">{{$t('landing.try')}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<style scoped></style>

<script lang="js">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Models',
    created() {
    },
    data() {
        return {

        }
    },
    methods: {

    }

});
</script>