<template>
    <div class="landing">
        <LandingPage></LandingPage>
    </div>
</template>

<script>
import LandingPage from '../components/LandingPage/LandingPage';
import { getRole } from '../services/auth-service.js';

export default {
  mounted() {
    if (this.$route.query.token) {
      localStorage.setItem('accessToken', this.$route.query.token);      
      //console.log("Google Login Complete");
      //console.log('accessToken ' + localStorage.getItem("accessToken"));
      
      getRole();
    }
  },
  name: 'Launch',
  components: {
    LandingPage,
  }
}
</script>
  
<style scoped>
.landing {
    height: 100%;
    width: 100%;
}

.hero {
    height: 100vh;
    width: 100vw;
}
</style>