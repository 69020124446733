<template>
    <div>
        <a href="http://localhost:8081/area.apk" download>DOWNLOAD APK LOLOLO</a>
    </div>
</template>
  
<style>

</style>
  
<script lang="js">
import { defineComponent } from 'vue';
import { redditAuth } from '../logServices.js';

export default defineComponent({
    name: 'ApkPage',
    data() {
        return {
        };
    },
    mounted() {
    },
    components: {
    },
    methods: {
        auth() {

        }
    }
})
</script>