import http from './http-requests-area';
import axios from 'axios';


// function creates a new camera.
export async function createCamera(camIp, modID) {
    let token = localStorage.getItem("token")
    return await axios({
        method: 'POST',
        url: 'http://localhost:8000/camera/create',
        headers: {
            'accept': "application/json",
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            "Camera_IP": camIp,
            "Model_ID": modID
        }
    });
}

// fetches existing cameras.
export async function fetchCameras(camIp, modID) {
    let token = localStorage.getItem("token")
    return await axios({
        method: 'GET',
        url: 'http://localhost:8000/camera/get',
        headers: {
            'accept': "application/json",
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
}

// deletes a camera.
export async function deleteCamera(camID) {
    let token = localStorage.getItem("token")
    return await axios({
        method: 'DELETE',
        url: `http://localhost:8000/camera/delete/${camID}`,
        headers: {
            'accept': "application/json",
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            "camera_id": camID,
        }
    });
}

// updates a camera.
export async function updateCamera(camID, model, cam_id) {
    let token = localStorage.getItem("token")
    return await axios({
        method: 'POST',
        url: `http://localhost:8000/camera/update/${cam_id}`,
        headers: {
            'accept': "application/json",
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            "Camera_IP": camID,
            "Model_ID": model,
        }
    });
}

// retrieves a live feed from a camera.
export async function getLiveFeed(camera, model) {
    //console.log("Enter")
    let token = localStorage.getItem("token")
    return await axios({
        method: 'POST',
        url: `http://localhost:8000/try/webcam/${camera}/${model}`,
        headers: {
            'accept': "application/json",
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
}

// performs model prediction on an image.
export async function tryModel(img, model) {
    let token = localStorage.getItem("token")
    let formdata = new FormData();
    formdata.append("file", img);
    //console.log(img);
    return await axios({
        method: 'POST',
        url: `http://localhost:8000/try/${model}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        },
        data: {
            "file": img
        }
    });
}
