<template>
    <section class="min-h-screen flex flex-col items-center justify-center px-4 bg-white ">
        <div class="flex flex-col items-center">
            <h3 class="font-semibold text-lg text-gray-700 tracking-wide">Admin Panel</h3>
            <p class="text-gray-500 my-1">The power of permission management</p>
        </div>

        <div class="flex justify-between mt-4">
            <div class="mr-4">
                <select @change="change($event)" v-model="selectedUsername" class="border rounded-md px-2 py-1">
                    <option v-for="username in usernames" :value="stringifyPermissions(username)">
                        {{ username.username }}

                    </option>
                </select>
            </div>

            <div class="ml-4">
                <select v-model="selectedPermission" class="border rounded-md px-2 py-1">
                    <option v-for="permission in permissions">
                        {{ permission.name }}
                    </option>
                </select>
            </div>
        </div>

        <div v-if="selectedUsername">
                <ul v-if="selectedUsername" class="text-xs ml-2 pl-2 border-l border-gray-300">
                    <li v-for="permission in selectedUserDetails?.permissions">
                        {{ permission }}
                    </li>
                </ul>
            </div>

        <div class="flex justify-between mt-4">
            <input type="checkbox" id="checkbox" v-model="checked" style="margin-right: 10px;" />
            <h3 class="font-semibold text-lg text-gray-700 tracking-wide">
                I confirm my action
                <span class="text-red-500 text-xs" v-if="!checked"> (Required)</span>
            </h3>
        </div>

        <div class="flex justify-between mt-4">
            <button @click="addPermission()"
                class="uppercase font-semibold tracking-wide bg-white-100 text-blue-700 px-4 py-2 rounded-lg mt-2 focus:outline-none hover:bg-blue-200 mr-4">
                ADD
            </button>

            <button @click="deletePermission()"
                class="uppercase font-semibold tracking-wide bg-white-100 text-red-700 px-4 py-2 rounded-lg mt-2 focus:outline-none hover:bg-red-200">
                DELETE
            </button>
        </div>
    </section>
</template>


<script lang="js">
import { defineComponent } from 'vue';
import { getAllUserInfo, getAllPermissions, addUserPermission, deleteUserPermission } from '../../../userService.js';
import { showToast } from '../../../utils/utils.js';

export default defineComponent({
    name: 'AdminPanel',
    components: {},
    data() {
        return {
            usernames: [],
            permissions: [],
            selectedUsername: null,
            selectedPermission: null,
            checked: false,
            selectedUserDetails: null,
        };
    },
    async mounted() {
        this.getNeedData();
    },
    methods: {
        async getNeedData() {
            try {
                const usernames = await getAllUserInfo();
                this.usernames = usernames.data;

                const permissions = await getAllPermissions();
                this.permissions = permissions.data;

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        parcePermissions() {
            return (JSON.parse())
        },
        stringifyPermissions(user) {
            const userDetails = {
                username: user.username,
                uuid: user.uuid,
                permissions: user.permissions.map((permission) => {
                return permission.name
            })}; 
            return (JSON.stringify(userDetails));
        },
        change() {
            this.selectedUserDetails = JSON.parse(this.selectedUsername);
            // //console.log("event " + event + this.selectedUsername.permissions);
        },
        addPermission() {
            if (this.checked && this.selectedUserDetails.username && this.selectedPermission) {

                const selectedUser = this.usernames.find(
                    (user) => user.username === this.selectedUserDetails.username,
                );

                const selectedPermission = this.permissions.find(
                    (permission) => permission.name === this.selectedPermission
                );

                if (selectedUser && selectedPermission) {
                    const userUuid = selectedUser.uuid;
                    const permissionUuid = selectedPermission.uuid;

                    addUserPermission(permissionUuid, userUuid).then((res) => {
                        showToast("success", "Permission successfully added");
                        this.getNeedData();
                        this.checked = false;
                        this.selectedPermission = [];
                        this.selectedUser = [];
                        this.selectedUserDetails = null;
                    }).catch((error) => {
                        console.log("Error: " + error);
                        showToast("error", "Error adding permission");
                    });
                };
            }
        },
        deletePermission() {

            if (this.checked && this.selectedUserDetails.username && this.selectedPermission) {

                const selectedUser = this.usernames.find(
                    (user) => user.username === this.selectedUserDetails.username,
                );

                const selectedPermission = this.permissions.find(
                    (permission) => permission.name === this.selectedPermission
                );

                if (selectedUser && selectedPermission) {
                    const userUuid = selectedUser.uuid;
                    const permissionUuid = selectedPermission.uuid;

                    deleteUserPermission(permissionUuid, userUuid).then((res) => {
                        showToast("success", "Permission successfully deleted");
                        this.getNeedData();
                        this.checked = false;
                        this.selectedPermission = [];
                        this.selectedUser = [];
                        this.selectedUserDetails = null;
                    }).catch((error) => {
                        showToast("error", "Error deleting permission");
                        console.log('error: ' + error)
                    });
                };
            }
        }
    }
});
</script>

<style scoped>
body {
    margin: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>            
