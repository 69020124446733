<template>
    <div class="hero">
        <div class="top">
            <div class="left">
                <h1 class="title">{{$t('landing.title')}}</h1>
                <p class="subtitle">{{$t('landing.subtitle')}}
                </p>
                <div class="buttons">
                    <a @click="scrollToDown()"
                        class="color: #ffffff inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
                        {{$t('landing.get_started')}}
                    </a>
                    <router-link
                        class="color: #ffffff inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-blue-700 rounded-lg bg-white hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
                        to="/aboutUs">
                        {{$t('landing.about_us')}}
                        <i class="pi pi-users pl-3" style="font-size: 1rem"></i>
                    </router-link>
                </div>
            </div>
            <div class="right">
                <img src="../../../../public/worker.jpg" alt="">
            </div>
        </div>
    </div>
</template> 

<style scoped>
.top {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(2, 0, 36);
    background: linear-gradient(166deg, #020024 0%, #090979 25%, rgba(1, 111, 234, 1) 42%, rgba(255, 255, 255, 1) 72%);
}

.right {
    display: flex;
    width: 1200px;
    height: 0;
    justify-content: center;
    align-items: center;
}

.right>img {
    width: 900px;
    height: auto;
    border-radius: 2em;
}

.hero {
    height: 100%;
    width: 100%;
    margin-bottom: 5%;
    display: flex;
    color: black;
    flex-direction: column;
}

* {
    color: #0049b0;
}

.buttons {
    margin-top: 5%;
    display: flex;
    width: 100%;
}

.left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    margin-right: auto;
    margin-left: auto;
}

.title {
    font-size: 70px;
    font-weight: 700;
    width: 400px;
    line-height: 75px;
    color: white;
}

.subtitle {
    font-size: 20px;
    font-weight: 500;
    width: 445px;
    color: white;
}

@media (max-width: 768px) {
    .modal {
        width: 80%;
        left: 10%;
    }

    .video {
        display: none;
    }

    .title {
        font-size: 42px;
    }

    .subtitle {
        font-size: 24px;
    }

    .left {
        margin: 1em;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .buttons {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .top {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        background: linear-gradient(167deg, rgba(0, 8, 209, 1) 0%, rgba(0, 187, 176, 1) 51%);
    }

    .signup {
        padding: 2px;
    }

    .right {
        display: none;
    }

    .title,
    .subtitle {
        width: 100%;
    }

    .right>img {
        display: none;
    }



    .whatis {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        height: fit-content;
        padding: 35px 5px;
    }

    .hero {
        height: 200vw;
        margin-bottom: 50px;
    }

}
</style>

<script lang="js">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Header',
    created() {
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        scrollToDown() {
            window.scrollTo({
                top: window.innerHeight,
                behavior: 'smooth'
            });
        }
    },
    components: {

    }

});
</script>